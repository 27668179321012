import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../components/context/AuthContext';
import axios from 'axios';
import { Alert, Spinner, Button, Card } from 'react-bootstrap';

const GoogleCallback = () => {
  const navigate = useNavigate();
  const { auth, updateUser } = useAuth();
  const [status, setStatus] = useState('processing');
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        const error = params.get('error');

        if (error) {
          throw new Error(decodeURIComponent(error));
        }

        if (!code) {
          throw new Error('No se recibió código de autorización');
        }

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/google/callback`,
          { code },
          {
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.token}`
            }
          }
        );

        if (response.data.error) {
          throw new Error(response.data.error);
        }

        updateUser({ 
          ...auth.user, 
          google_connected: true,
          google_customer_id: response.data.google_customer_id
        });

        setStatus('success');
        setTimeout(() => navigate('/dashboard'), 1500);
      } catch (err) {
        console.error('Error en Google callback:', err);
        setError(err.message || 'Error al procesar la autenticación de Google');
        setStatus('error');
      }
    };

    handleCallback();
  }, [auth, updateUser, navigate]);

  const renderContent = () => {
    switch (status) {
      case 'processing':
        return (
          <Card className="text-center p-4">
            <Card.Body>
              <div className="d-flex flex-column align-items-center">
                <Spinner 
                  animation="border" 
                  variant="primary" 
                  className="mb-3"
                />
                <h3 className="h4 mb-2">Conectando con Google Ads</h3>
                <p className="text-muted">
                  Por favor espere mientras procesamos su conexión...
                </p>
              </div>
            </Card.Body>
          </Card>
        );

      case 'success':
        return (
          <Alert variant="success">
            <Alert.Heading className="d-flex align-items-center">
              <div 
                className="bg-success rounded-circle me-2" 
                style={{ width: '8px', height: '8px' }}
              />
              Conexión exitosa
            </Alert.Heading>
            <p>
              Su cuenta de Google Ads ha sido conectada correctamente.
              Redirigiendo al dashboard...
            </p>
          </Alert>
        );

      case 'error':
        return (
          <Alert variant="danger">
            <Alert.Heading>Error de autenticación</Alert.Heading>
            <p>{error}</p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-danger"
                onClick={() => navigate('/dashboard')}
              >
                Volver al dashboard
              </Button>
            </div>
          </Alert>
        );

      default:
        return null;
    }
  };

  return (
    <div className="min-vh-100 d-flex align-items-center justify-content-center p-4">
      <div className="w-100" style={{ maxWidth: '500px' }}>
        {renderContent()}
      </div>
    </div>
  );
};

export default GoogleCallback;