// src/pages/accounts/AccountsConnection.jsx

import React, { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext } from '../../components/context/AuthContext';
import { useLocation } from 'react-router-dom';
import {
  Link as LinkIcon,
  Check,
  AlertCircle,
  Settings,
  RefreshCw,
  Facebook,
  ChevronDown,
  XCircle,
  RefreshCcw,
  CheckCircle,
  DollarSign,
  Clock,
  Target,
} from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
// Importaciones al inicio del archivo
import { FaGoogle } from 'react-icons/fa'; // Importa el icono de Font Awesome


const API_URL = process.env.REACT_APP_API_URL;

const AccountsConnection = () => {
  const location = useLocation();
  const { auth, updateUser } = useContext(AuthContext);

  // Estados de conexión
  const [isConnectingMeta, setIsConnectingMeta] = useState(false);
  const [isConnectingGoogle, setIsConnectingGoogle] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Estados de Meta Ads
  const [metaAccounts, setMetaAccounts] = useState([]);
  const [selectedMetaAccount, setSelectedMetaAccount] = useState(null);
  const [expandedMetaAccount, setExpandedMetaAccount] = useState(null);
  const [metaCampaigns, setMetaCampaigns] = useState({});

  // Estados de Google Ads
  const [googleAccounts, setGoogleAccounts] = useState([]);
  const [selectedGoogleAccount, setSelectedGoogleAccount] = useState(null);
  const [expandedGoogleAccount, setExpandedGoogleAccount] = useState(null);
  const [googleCampaigns, setGoogleCampaigns] = useState({});

  // Estados de carga
  const [isLoadingAccounts, setIsLoadingAccounts] = useState({
    meta: false,
    google: false,
  });
  const [loadingCampaigns, setLoadingCampaigns] = useState({});

  // Efecto para cargar cuentas al montar el componente
  useEffect(() => {
    if (location.state?.metaConnected) {
      setSuccess('Cuenta de Meta Ads conectada exitosamente');
      window.history.replaceState({}, document.title);
    }
    if (location.state?.googleConnected) {
      setSuccess('Cuenta de Google Ads conectada exitosamente');
      window.history.replaceState({}, document.title);
    }

    // Cargar cuentas si están conectadas
    if (auth.user?.meta_connected) {
      fetchMetaAccounts();
    }
    if (auth.user?.google_connected) {
      fetchGoogleAccounts();
    }
  }, [location.state, auth.user?.meta_connected, auth.user?.google_connected]);

  // Funciones de utilidad
  const formatCurrency = (amount) => {
    if (!amount) return '$0.00';
    return new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    }).format(amount);
  };

  // Función para conectar Meta Ads
  const connectMetaAds = () => {
    setIsConnectingMeta(true);
    setError('');
    setSuccess('');

    const state = uuidv4();
    localStorage.setItem('facebook_auth_state', state);

    const appId = process.env.REACT_APP_FACEBOOK_APP_ID;
    const redirectUri = encodeURIComponent(`${window.location.origin}/facebook-callback`);
    const scopes = [
      'email',
      'public_profile',
      'ads_read',
      'ads_management',
      'business_management',
    ];

    const scope = encodeURIComponent(scopes.join(','));

    const authUrl =
      `https://www.facebook.com/v18.0/dialog/oauth` +
      `?client_id=${appId}` +
      `&redirect_uri=${redirectUri}` +
      `&scope=${scope}` +
      `&response_type=code` +
      `&state=${state}` +
      `&auth_type=rerequest`;

    window.location.href = authUrl;
  };

  // Función para conectar Google Ads
  const connectGoogleAds = () => {
    setIsConnectingGoogle(true);
    setError('');
    setSuccess('');

    const state = uuidv4();
    localStorage.setItem('google_auth_state', state);

    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const redirectUri = encodeURIComponent(`${window.location.origin}/google-callback`);
    const scopes = [
      'https://www.googleapis.com/auth/adwords',
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile',
    ];

    const scope = encodeURIComponent(scopes.join(' '));

    const authUrl =
      `https://accounts.google.com/o/oauth2/v2/auth` +
      `?client_id=${clientId}` +
      `&redirect_uri=${redirectUri}` +
      `&scope=${scope}` +
      `&response_type=code` +
      `&access_type=offline` +
      `&state=${state}` +
      `&prompt=consent`;

    window.location.href = authUrl;
  };

  // Función para obtener cuentas de Meta Ads
  const fetchMetaAccounts = useCallback(async () => {
    setIsLoadingAccounts((prev) => ({ ...prev, meta: true }));
    try {
      const response = await axios.get(`${API_URL}/api/meta/accounts`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.data && Array.isArray(response.data)) {
        setMetaAccounts(response.data);

        if (auth.user?.meta_ad_account_id) {
          const currentAccount = response.data.find(
            (acc) => acc.account_id === auth.user.meta_ad_account_id
          );
          if (currentAccount) {
            setSelectedMetaAccount(currentAccount);
            await fetchMetaCampaigns(currentAccount.account_id);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching Meta accounts:', error);
      const errorMessage = error.response?.data?.error || error.message;
      setError(`Error al obtener las cuentas de Meta Ads: ${errorMessage}`);

      if (error.response?.status === 401) {
        updateUser({
          ...auth.user,
          meta_connected: false,
          meta_ad_account_id: null,
        });
      }
    } finally {
      setIsLoadingAccounts((prev) => ({ ...prev, meta: false }));
    }
  }, [API_URL, auth.token, auth.user, updateUser]);

  // Función para obtener cuentas de Google Ads
  const fetchGoogleAccounts = useCallback(async () => {
    setIsLoadingAccounts((prev) => ({ ...prev, google: true }));
    try {
      const response = await axios.get(`${API_URL}/api/google/accounts`, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.data && Array.isArray(response.data)) {
        setGoogleAccounts(response.data);

        if (auth.user?.google_ad_account_id) {
          const currentAccount = response.data.find(
            (acc) => acc.customer_id === auth.user.google_ad_account_id
          );
          if (currentAccount) {
            setSelectedGoogleAccount(currentAccount);
            await fetchGoogleCampaigns(currentAccount.customer_id);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching Google accounts:', error);
      const errorMessage = error.response?.data?.error || error.message;
      setError(`Error al obtener las cuentas de Google Ads: ${errorMessage}`);

      if (error.response?.status === 401) {
        updateUser({
          ...auth.user,
          google_connected: false,
          google_ad_account_id: null,
        });
      }
    } finally {
      setIsLoadingAccounts((prev) => ({ ...prev, google: false }));
    }
  }, [API_URL, auth.token, auth.user, updateUser]);

  // Función para obtener campañas de una cuenta de Meta Ads
  const fetchMetaCampaigns = useCallback(
    async (accountId) => {
      if (!accountId || loadingCampaigns[accountId]) return;

      setLoadingCampaigns((prev) => ({ ...prev, [accountId]: true }));
      try {
        const response = await axios.get(`${API_URL}/api/meta/campaigns/${accountId}`, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.data && Array.isArray(response.data)) {
          setMetaCampaigns((prev) => ({
            ...prev,
            [accountId]: response.data,
          }));
        }
      } catch (error) {
        console.error('Error fetching Meta campaigns:', error);
        setError(`Error al obtener campañas: ${error.message}`);
      } finally {
        setLoadingCampaigns((prev) => ({ ...prev, [accountId]: false }));
      }
    },
    [API_URL, auth.token, loadingCampaigns]
  );

  // Función para obtener campañas de una cuenta de Google Ads
  const fetchGoogleCampaigns = useCallback(
    async (customerId) => {
      if (!customerId || loadingCampaigns[customerId]) return;

      setLoadingCampaigns((prev) => ({ ...prev, [customerId]: true }));
      try {
        const response = await axios.get(`${API_URL}/api/google/campaigns/${customerId}`, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.data && Array.isArray(response.data)) {
          setGoogleCampaigns((prev) => ({
            ...prev,
            [customerId]: response.data,
          }));
        }
      } catch (error) {
        console.error('Error fetching Google campaigns:', error);
        setError(`Error al obtener campañas: ${error.message}`);
      } finally {
        setLoadingCampaigns((prev) => ({ ...prev, [customerId]: false }));
      }
    },
    [API_URL, auth.token, loadingCampaigns]
  );

  // Función para seleccionar una cuenta de Meta Ads
  const selectMetaAccount = async (account) => {
    try {
      await axios.post(
        `${API_URL}/api/meta/select-account`,
        { account_id: account.account_id },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setSelectedMetaAccount(account);
      updateUser({
        ...auth.user,
        meta_ad_account_id: account.account_id,
      });
      setSuccess('Cuenta de Meta Ads actualizada correctamente');
      await fetchMetaCampaigns(account.account_id);
    } catch (error) {
      console.error('Error selecting Meta account:', error);
      setError(`Error al seleccionar la cuenta: ${error.message}`);
    }
  };

  // Función para seleccionar una cuenta de Google Ads
  const selectGoogleAccount = async (account) => {
    try {
      await axios.post(
        `${API_URL}/api/google/select-account`,
        { customer_id: account.customer_id },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setSelectedGoogleAccount(account);
      updateUser({
        ...auth.user,
        google_ad_account_id: account.customer_id,
      });
      setSuccess('Cuenta de Google Ads actualizada correctamente');
      await fetchGoogleCampaigns(account.customer_id);
    } catch (error) {
      console.error('Error selecting Google account:', error);
      setError(`Error al seleccionar la cuenta: ${error.message}`);
    }
  };

  // Función para desconectar Meta Ads
  const disconnectMetaAds = async () => {
    try {
      await axios.post(
        `${API_URL}/api/meta/disconnect`,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      );
      setSelectedMetaAccount(null);
      setMetaAccounts([]);
      setMetaCampaigns({});
      updateUser({
        ...auth.user,
        meta_connected: false,
        meta_ad_account_id: null,
      });
      setSuccess('Cuenta de Meta Ads desconectada correctamente');
    } catch (error) {
      console.error('Error disconnecting Meta account:', error);
      setError('Error al desconectar la cuenta de Meta Ads');
    }
  };

  // Función para desconectar Google Ads
  const disconnectGoogleAds = async () => {
    try {
      await axios.post(
        `${API_URL}/api/google/disconnect`,
        {},
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      );
      setSelectedGoogleAccount(null);
      setGoogleAccounts([]);
      setGoogleCampaigns({});
      updateUser({
        ...auth.user,
        google_connected: false,
        google_ad_account_id: null,
      });
      setSuccess('Cuenta de Google Ads desconectada correctamente');
    } catch (error) {
      console.error('Error disconnecting Google account:', error);
      setError('Error al desconectar la cuenta de Google Ads');
    }
  };

  // Renderizar sección de Meta Ads
  const renderMetaAdsSection = () => (
    <div className="bg-white rounded-lg shadow-sm overflow-hidden">
      <div className="p-6 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <div className="p-3 rounded-full bg-blue-100">
              <Facebook className="h-6 w-6 text-blue-600" />
            </div>
            <div>
              <h2 className="text-lg font-semibold text-gray-900">Meta Ads</h2>
              <p className="text-sm text-gray-600">
                {auth.user?.meta_connected
                  ? 'Cuenta conectada y activa'
                  : 'Conecta tu cuenta de Meta Ads'}
              </p>
            </div>
          </div>

          {auth.user?.meta_connected ? (
            <div className="flex items-center space-x-3">
              <span className="flex items-center text-green-600">
                <Check className="h-5 w-5 mr-1" />
                <span className="text-sm font-medium">Conectado</span>
              </span>
              <button
                onClick={disconnectMetaAds}
                className="p-1.5 rounded-full text-gray-400 hover:text-red-500 hover:bg-red-50 transition-colors"
                title="Desconectar cuenta"
              >
                <XCircle className="h-5 w-5" />
              </button>
            </div>
          ) : (
            <button
              onClick={connectMetaAds}
              disabled={isConnectingMeta}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
            >
              {isConnectingMeta ? (
                <>
                  <RefreshCw className="h-4 w-4 mr-2 animate-spin" />
                  Conectando...
                </>
              ) : (
                <>
                  <LinkIcon className="h-4 w-4 mr-2" />
                  Conectar
                </>
              )}
            </button>
          )}
        </div>
      </div>

      {/* Lista de cuentas de Meta */}
      {auth.user?.meta_connected && (
        <div className="p-6">
          {isLoadingAccounts.meta ? (
            <div className="flex justify-center py-8">
              <RefreshCw className="h-8 w-8 animate-spin text-blue-600" />
            </div>
          ) : metaAccounts.length > 0 ? (
            <div className="space-y-4">
              {metaAccounts.map((account) => (
                <div
                  key={account.account_id}
                  className="border border-gray-200 rounded-lg overflow-hidden"
                >
                  <div
                    className="p-4 bg-white hover:bg-gray-50 cursor-pointer transition-colors"
                    onClick={() => {
                      setExpandedMetaAccount(
                        expandedMetaAccount === account.account_id ? null : account.account_id
                      );
                      if (!metaCampaigns[account.account_id]) {
                        fetchMetaCampaigns(account.account_id);
                      }
                    }}
                  >
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="font-medium text-gray-900">
                          {account.name || `Cuenta ${account.account_id}`}
                        </h3>
                        <p className="text-sm text-gray-500">ID: {account.account_id}</p>
                      </div>
                      <div className="flex items-center space-x-3">
                        <span
                          className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                            account.status === 'ACTIVE'
                              ? 'bg-green-100 text-green-800'
                              : 'bg-yellow-100 text-yellow-800'
                          }`}
                        >
                          {account.status}
                        </span>
                        {!account.is_selected && (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              selectMetaAccount(account);
                            }}
                            className="inline-flex items-center px-3 py-1 text-sm font-medium text-blue-600 bg-blue-100 rounded-md hover:bg-blue-200 transition-colors"
                          >
                            Seleccionar
                          </button>
                        )}
                        <ChevronDown
                          className={`h-5 w-5 text-gray-400 transition-transform duration-200 ${
                            expandedMetaAccount === account.account_id ? 'rotate-180' : ''
                          }`}
                        />
                      </div>
                    </div>
                  </div>

                  {expandedMetaAccount === account.account_id && (
                    <div className="border-t border-gray-200 bg-gray-50 p-4">
                      <div className="grid grid-cols-3 gap-4 mb-6">
                        <div className="bg-white p-4 rounded-lg shadow-sm">
                          <div className="flex items-center text-sm text-gray-500 mb-1">
                            <DollarSign className="h-4 w-4 mr-1" />
                            Balance
                          </div>
                          <div className="text-lg font-semibold text-gray-900">
                            {formatCurrency(account.balance)}
                          </div>
                        </div>
                        <div className="bg-white p-4 rounded-lg shadow-sm">
                          <div className="flex items-center text-sm text-gray-500 mb-1">
                            <Clock className="h-4 w-4 mr-1" />
                            Zona Horaria
                          </div>
                          <div className="text-lg font-semibold text-gray-900">
                            {account.timezone}
                          </div>
                        </div>
                        <div className="bg-white p-4 rounded-lg shadow-sm">
                          <div className="flex items-center text-sm text-gray-500 mb-1">
                            <Target className="h-4 w-4 mr-1" />
                            Moneda
                          </div>
                          <div className="text-lg font-semibold text-gray-900">
                            {account.currency}
                          </div>
                        </div>
                      </div>

                      {/* Sección de campañas */}
                      <div>
                        <div className="flex items-center justify-between mb-4">
                          <h4 className="text-sm font-medium text-gray-900">Campañas</h4>
                          <button
                            onClick={() => fetchMetaCampaigns(account.account_id)}
                            className="inline-flex items-center px-2 py-1 text-sm text-gray-600 hover:text-gray-900 transition-colors"
                          >
                            <RefreshCcw className="h-4 w-4 mr-1" />
                            Actualizar
                          </button>
                        </div>

                        {loadingCampaigns[account.account_id] ? (
                          <div className="flex justify-center py-4">
                            <RefreshCw className="h-6 w-6 animate-spin text-blue-600" />
                          </div>
                        ) : metaCampaigns[account.account_id]?.length > 0 ? (
                          <div className="space-y-3">
                            {metaCampaigns[account.account_id].map((campaign) => (
                              <div
                                key={campaign.id}
                                className="bg-white p-4 rounded-lg shadow-sm"
                              >
                                <div className="flex items-center justify-between">
                                  <div>
                                    <h5 className="font-medium text-gray-900">{campaign.name}</h5>
                                    <p className="text-sm text-gray-500">
                                      {campaign.objective}
                                      <span className="mx-2">•</span>
                                      {formatCurrency(campaign.budget)}/día
                                    </p>
                                  </div>
                                  <span
                                    className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                      campaign.status === 'ACTIVE'
                                        ? 'bg-green-100 text-green-800'
                                        : 'bg-red-100 text-red-800'
                                    }`}
                                  >
                                    {campaign.status}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="text-center py-4 text-gray-500">
                            No hay campañas activas
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-6">
              <p className="text-gray-500 mb-2">No se encontraron cuentas de Meta Ads</p>
              <button
                onClick={fetchMetaAccounts}
                className="text-blue-600 hover:text-blue-700 font-medium transition-colors"
              >
                Intentar nuevamente
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );

  // Renderizar sección de Google Ads
  const renderGoogleAdsSection = () => (
    <div className="bg-white rounded-lg shadow-sm overflow-hidden">
      <div className="p-6 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <div className="p-3 rounded-full bg-red-100">
              <FaGoogle className="h-6 w-6 text-red-600" />
            </div>
            <div>
              <h2 className="text-lg font-semibold text-gray-900">Google Ads</h2>
              <p className="text-sm text-gray-600">
                {auth.user?.google_connected
                  ? 'Cuenta conectada y activa'
                  : 'Conecta tu cuenta de Google Ads'}
              </p>
            </div>
          </div>

          {auth.user?.google_connected ? (
            <div className="flex items-center space-x-3">
              <span className="flex items-center text-green-600">
                <Check className="h-5 w-5 mr-1" />
                <span className="text-sm font-medium">Conectado</span>
              </span>
              <button
                onClick={disconnectGoogleAds}
                className="p-1.5 rounded-full text-gray-400 hover:text-red-500 hover:bg-red-50 transition-colors"
                title="Desconectar cuenta"
              >
                <XCircle className="h-5 w-5" />
              </button>
            </div>
          ) : (
            <button
              onClick={connectGoogleAds}
              disabled={isConnectingGoogle}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
            >
              {isConnectingGoogle ? (
                <>
                  <RefreshCw className="h-4 w-4 mr-2 animate-spin" />
                  Conectando...
                </>
              ) : (
                <>
                  <LinkIcon className="h-4 w-4 mr-2" />
                  Conectar
                </>
              )}
            </button>
          )}
        </div>
      </div>

      {/* Lista de cuentas de Google */}
      {auth.user?.google_connected && (
        <div className="p-6">
          {isLoadingAccounts.google ? (
            <div className="flex justify-center py-8">
              <RefreshCw className="h-8 w-8 animate-spin text-red-600" />
            </div>
          ) : googleAccounts.length > 0 ? (
            <div className="space-y-4">
              {googleAccounts.map((account) => (
                <div
                  key={account.customer_id}
                  className="border border-gray-200 rounded-lg overflow-hidden"
                >
                  <div
                    className="p-4 bg-white hover:bg-gray-50 cursor-pointer transition-colors"
                    onClick={() => {
                      setExpandedGoogleAccount(
                        expandedGoogleAccount === account.customer_id ? null : account.customer_id
                      );
                      if (!googleCampaigns[account.customer_id]) {
                        fetchGoogleCampaigns(account.customer_id);
                      }
                    }}
                  >
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="font-medium text-gray-900">
                          {account.descriptive_name || `Cuenta ${account.customer_id}`}
                        </h3>
                        <p className="text-sm text-gray-500">ID: {account.customer_id}</p>
                      </div>
                      <div className="flex items-center space-x-3">
                        <span
                          className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                            account.status === 'ENABLED'
                              ? 'bg-green-100 text-green-800'
                              : 'bg-yellow-100 text-yellow-800'
                          }`}
                        >
                          {account.status}
                        </span>
                        {!account.is_selected && (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              selectGoogleAccount(account);
                            }}
                            className="inline-flex items-center px-3 py-1 text-sm font-medium text-red-600 bg-red-100 rounded-md hover:bg-red-200 transition-colors"
                          >
                            Seleccionar
                          </button>
                        )}
                        <ChevronDown
                          className={`h-5 w-5 text-gray-400 transition-transform duration-200 ${
                            expandedGoogleAccount === account.customer_id ? 'rotate-180' : ''
                          }`}
                        />
                      </div>
                    </div>
                  </div>

                  {expandedGoogleAccount === account.customer_id && (
                    <div className="border-t border-gray-200 bg-gray-50 p-4">
                      <div className="grid grid-cols-3 gap-4 mb-6">
                        <div className="bg-white p-4 rounded-lg shadow-sm">
                          <div className="flex items-center text-sm text-gray-500 mb-1">
                            <DollarSign className="h-4 w-4 mr-1" />
                            Balance
                          </div>
                          <div className="text-lg font-semibold text-gray-900">
                            {formatCurrency(account.balance)}
                          </div>
                        </div>
                        <div className="bg-white p-4 rounded-lg shadow-sm">
                          <div className="flex items-center text-sm text-gray-500 mb-1">
                            <Clock className="h-4 w-4 mr-1" />
                            Zona Horaria
                          </div>
                          <div className="text-lg font-semibold text-gray-900">
                            {account.time_zone}
                          </div>
                        </div>
                        <div className="bg-white p-4 rounded-lg shadow-sm">
                          <div className="flex items-center text-sm text-gray-500 mb-1">
                            <Target className="h-4 w-4 mr-1" />
                            Moneda
                          </div>
                          <div className="text-lg font-semibold text-gray-900">
                            {account.currency_code}
                          </div>
                        </div>
                      </div>

                      {/* Sección de campañas */}
                      <div>
                        <div className="flex items-center justify-between mb-4">
                          <h4 className="text-sm font-medium text-gray-900">Campañas</h4>
                          <button
                            onClick={() => fetchGoogleCampaigns(account.customer_id)}
                            className="inline-flex items-center px-2 py-1 text-sm text-gray-600 hover:text-gray-900 transition-colors"
                          >
                            <RefreshCcw className="h-4 w-4 mr-1" />
                            Actualizar
                          </button>
                        </div>

                        {loadingCampaigns[account.customer_id] ? (
                          <div className="flex justify-center py-4">
                            <RefreshCw className="h-6 w-6 animate-spin text-red-600" />
                          </div>
                        ) : googleCampaigns[account.customer_id]?.length > 0 ? (
                          <div className="space-y-3">
                            {googleCampaigns[account.customer_id].map((campaign) => (
                              <div
                                key={campaign.id}
                                className="bg-white p-4 rounded-lg shadow-sm"
                              >
                                <div className="flex items-center justify-between">
                                  <div>
                                    <h5 className="font-medium text-gray-900">{campaign.name}</h5>
                                    <p className="text-sm text-gray-500">
                                      {campaign.advertising_channel_type}
                                      <span className="mx-2">•</span>
                                      {formatCurrency(campaign.budget_amount_micro / 1e6)}/día
                                    </p>
                                  </div>
                                  <span
                                    className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                      campaign.status === 'ENABLED'
                                        ? 'bg-green-100 text-green-800'
                                        : 'bg-red-100 text-red-800'
                                    }`}
                                  >
                                    {campaign.status}
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="text-center py-4 text-gray-500">
                            No hay campañas activas
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-6">
              <p className="text-gray-500 mb-2">No se encontraron cuentas de Google Ads</p>
              <button
                onClick={fetchGoogleAccounts}
                className="text-red-600 hover:text-red-700 font-medium transition-colors"
              >
                Intentar nuevamente
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );

  // Render principal
  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <div className="bg-white border-b shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <div className="p-3 rounded-full bg-indigo-100">
                <Settings className="w-6 h-6 text-indigo-600" />
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">Conexión de Cuentas</h1>
                <p className="text-sm text-gray-600">
                  Gestiona tus conexiones con plataformas publicitarias
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        {/* Alerts */}
        {error && (
          <div className="mb-6 flex items-center p-4 bg-red-50 rounded-lg text-red-700">
            <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
            <p>{error}</p>
          </div>
        )}
        {success && (
          <div className="mb-6 flex items-center p-4 bg-green-50 rounded-lg text-green-700">
            <CheckCircle className="h-5 w-5 mr-2 flex-shrink-0" />
            <p>{success}</p>
          </div>
        )}

        {/* Accounts Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Render Meta Ads Section */}
          {renderMetaAdsSection()}
          {/* Render Google Ads Section */}
          {renderGoogleAdsSection()}
        </div>
      </div>
    </div>
  );
};

export default AccountsConnection;
