// Dashboard.jsx
import React, { useState, useEffect } from 'react';
import AICampaignCreation from '../../components/AiCampaignCreation';
import Sidebar from '../../components/sidebar';

const Dashboard = () => {
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileOrTablet(window.innerWidth < 1024);
    };
    
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="flex h-screen bg-gray-50">
      {/* Sidebar con estado controlado */}
      <Sidebar 
        onExpandedChange={setSidebarExpanded}
      />
      
      {/* Main Content con margin dinámico solo en desktop */}
      <main className={`flex-1 h-screen transition-all duration-300 ease-in-out
        ${!isMobileOrTablet && sidebarExpanded ? 'lg:ml-64' : 'lg:ml-16'}`}>
        <AICampaignCreation />
      </main>
    </div>
  );
};

export default Dashboard;