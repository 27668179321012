import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { 
  User, Mail, Lock, Camera, Bell, Globe, Save, 
  X, Menu, CreditCard, Shield, 
  HelpCircle, ExternalLink, FileText,
  Smartphone, History, BookOpen,
  CheckCircle, XCircle
} from 'lucide-react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import Sidebar from '../../components/sidebar';
import { 
  Row, Col, Form, Button, 
  Spinner, Modal, Table, Badge, Accordion 
} from 'react-bootstrap';
import { useAuth } from '../../components/context/AuthContext';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const Profile = () => {
  const navigate = useNavigate();
  const { auth, updateUser } = useAuth();
  
  // Estados principales
  const [activeTab, setActiveTab] = useState('personal');
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);
  const [errors, setErrors] = useState({});
  const [profileImage, setProfileImage] = useState(null);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [pageError, setPageError] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  // Estados adicionales
  const [billingHistory, setBillingHistory] = useState([]);
  const [subscriptionStatus, setSubscriptionStatus] = useState('free');
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [showQRModal, setShowQRModal] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [activeSessions, setActiveSessions] = useState([]);
  const [activityHistory, setActivityHistory] = useState([]);
  const [showEndSessionModal, setShowEndSessionModal] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const MERCADOPAGO_PUBLIC_KEY = process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY;
  // Estado del formulario principal
  const [formData, setFormData] = useState({
    // Información básica
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    age: '',
    // Información profesional
    marketingExperience: '',
    industry: '',
    companySize: '',
    // Objetivos y preferencias
    mainGoals: [],
    preferredPlatforms: [],
    monthlyBudget: '',
    hasTeam: false,
    teamSize: '',
    // Configuración
    language: 'es',
    theme: 'light',
    notifications: {
      email: true,
      push: true,
      marketing: false
    },
    // Seguridad
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const [initialFormData, setInitialFormData] = useState({});

  // Efectos
  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    setHasUnsavedChanges(JSON.stringify(formData) !== JSON.stringify(initialFormData));
  }, [formData, initialFormData]);

  useEffect(() => {
    if (activeTab === 'security') {
      fetchSecurityData();
    }
  }, [activeTab]);

  const fetchUserData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('/user');
      const userData = response.data;

      console.log('Datos del usuario:', userData); // Para depuración

      const newFormData = {
        name: userData.name || '',
        firstName: userData.first_name || '',
        lastName: userData.last_name || '',
        email: userData.email || '',
        age: userData.age || '',
        marketingExperience: userData.marketing_experience || '',
        industry: userData.industry || '',
        companySize: userData.company_size || '',
        mainGoals: userData.main_goals || [],
        preferredPlatforms: userData.preferred_platforms || [],
        monthlyBudget: userData.monthly_budget || '',
        hasTeam: userData.has_team || false,
        teamSize: userData.team_size || '',
        language: userData.language || 'es',
        theme: userData.theme || 'light',
        notifications: userData.notifications || {
          email: true,
          push: true,
          marketing: false
        }
      };

      setFormData(newFormData);
      setInitialFormData(newFormData);
      setProfileImage(userData.profile_image);
      setTwoFactorEnabled(userData.two_factor_enabled || false);
      setSubscriptionStatus(userData.subscription_status || 'free');

      // Actualiza el contexto de autenticación
      updateUser(userData);

    } catch (error) {
      console.error('Error fetching user data:', error);
      setPageError('Error al cargar los datos del usuario');
    } finally {
      setLoading(false);
    }
  };

  const fetchSecurityData = async () => {
    try {
      const [sessionsRes, activityRes] = await Promise.all([
        axiosInstance.get('/security/sessions'),
        axiosInstance.get('/security/activity')
      ]);

      setActiveSessions(sessionsRes.data);
      setActivityHistory(activityRes.data);
    } catch (error) {
      console.error('Error fetching security data:', error);
      setNotification({
        type: 'error',
        message: 'Error al cargar los datos de seguridad'
      });
    }
  };

  // Manejadores de eventos
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: null }));
    }
  };

  const handleMultiSelect = (name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: prev[name].includes(value)
        ? prev[name].filter(item => item !== value)
        : [...prev[name], value]
    }));
  };

  const handleNotificationChange = (key) => {
    setFormData(prev => ({
      ...prev,
      notifications: {
        ...prev.notifications,
        [key]: !prev.notifications[key]
      }
    }));
  };
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formDataImg = new FormData();
      formDataImg.append('profileImage', file);
      
      try {
        setLoading(true);
        // Notar el cambio en la ruta para que coincida con el backend
        const response = await axiosInstance.post('/api/user/profile-image', formDataImg, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        const newProfileImageUrl = response.data.imageUrl;
        setProfileImage(newProfileImageUrl);
        updateUser({ ...auth.user, profile_image: newProfileImageUrl });
        setNotification({
          type: 'success',
          message: 'Imagen de perfil actualizada correctamente'
        });
      } catch (error) {
        console.error('Error uploading profile image:', error);
        setNotification({
          type: 'error',
          message: error.response?.data?.error || 'Error al actualizar la imagen de perfil'
        });
      } finally {
        setLoading(false);
      }
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    
    setLoading(true);
    try {
      const dataToSend = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        age: formData.age,
        marketing_experience: formData.marketingExperience,
        industry: formData.industry,
        company_size: formData.companySize,
        main_goals: formData.mainGoals,
        preferred_platforms: formData.preferredPlatforms,
        monthly_budget: formData.monthlyBudget,
        has_team: formData.hasTeam,
        team_size: formData.teamSize,
        language: formData.language,
        theme: formData.theme,
        notification_email: formData.notifications.email,
        notification_push: formData.notifications.push,
        notification_marketing: formData.notifications.marketing,
        // Agrega cualquier otro campo necesario
      };

      const response = await axiosInstance.put('/user', dataToSend);
      updateUser(response.data);
      setNotification({
        type: 'success',
        message: 'Perfil actualizado correctamente'
      });
      setInitialFormData(formData);
    } catch (error) {
      console.error('Error updating profile:', error);
      setNotification({
        type: 'error',
        message: error.response?.data?.message || 'Error al actualizar el perfil'
      });
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = 'El nombre es requerido';
    if (!formData.lastName) newErrors.lastName = 'El apellido es requerido';
    
    if (activeTab === 'security' && formData.newPassword) {
      if (!formData.currentPassword) {
        newErrors.currentPassword = 'La contraseña actual es requerida';
      }
      if (formData.newPassword !== formData.confirmPassword) {
        newErrors.confirmPassword = 'Las contraseñas no coinciden';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
    // Funciones de seguridad
    const initiate2FA = async () => {
      try {
        const response = await axiosInstance.post('/security/2fa/init');
        setQrCode(response.data.qrCode);
        setShowQRModal(true);
      } catch (error) {
        console.error('Error initiating 2FA:', error);
        setNotification({
          type: 'error',
          message: 'Error al iniciar la autenticación de dos factores'
        });
      }
    };
  // Función para manejar el cierre de sesión
  const handleEndSession = (sessionId) => {
    setSelectedSessionId(sessionId);
    setShowEndSessionModal(true);
  };

  // Función para ejecutar el cierre de sesión
  const executeEndSession = async () => {
    if (!selectedSessionId) return;
    
    try {
      setLoading(true);
      await axiosInstance.delete(`/security/sessions/${selectedSessionId}`);
      
      setActiveSessions(prev => prev.filter(session => session.id !== selectedSessionId));
      setNotification({
        type: 'success',
        message: 'Sesión terminada correctamente'
      });
    } catch (error) {
      console.error('Error ending session:', error);
      setNotification({
        type: 'error',
        message: 'Error al terminar la sesión'
      });
    } finally {
      setLoading(false);
      setShowEndSessionModal(false);
      setSelectedSessionId(null);
    }
  };

  // Función para descartar cambios
  const handleDiscardChanges = useCallback(() => {
    setFormData(initialFormData);
    setShowUnsavedChangesModal(false);
    navigate(-1);
  }, [initialFormData, navigate]);
    const verify2FA = async () => {
      try {
        await axiosInstance.post('/security/2fa/verify', { code: verificationCode });
        setTwoFactorEnabled(true);
        setShowQRModal(false);
        setNotification({
          type: 'success',
          message: 'Autenticación de dos factores activada correctamente'
        });
      } catch (error) {
        console.error('Error verifying 2FA:', error);
        setNotification({
          type: 'error',
          message: 'Código de verificación inválido'
        });
      }
    };
    const renderTabContent = () => {
      switch (activeTab) {
        case 'personal':
          return (
            <div className="space-y-6">
              <div className="bg-white rounded-lg shadow p-6">
                <h4 className="text-xl font-semibold mb-6">Información Personal</h4>
                <Form onSubmit={handleSubmit}>
                  {/* Datos básicos */}
                  <Row className="mb-4">
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label className="font-medium">Nombre</Form.Label>
                        <div className="input-group">
                          <span className="input-group-text bg-light border-0">
                            <User size={18} className="text-primary" />
                          </span>
                          <Form.Control
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleInputChange}
                            placeholder="Tu nombre"
                            className={`bg-light ${errors.firstName ? 'is-invalid' : ''}`}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label className="font-medium">Apellido</Form.Label>
                        <div className="input-group">
                          <span className="input-group-text bg-light border-0">
                            <User size={18} className="text-primary" />
                          </span>
                          <Form.Control
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleInputChange}
                            placeholder="Tu apellido"
                            className={`bg-light ${errors.lastName ? 'is-invalid' : ''}`}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mb-4">
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label className="font-medium">Email</Form.Label>
                        <div className="input-group">
                          <span className="input-group-text bg-light border-0">
                            <Mail size={18} className="text-primary" />
                          </span>
                          <Form.Control
                            type="email"
                            value={formData.email}
                            readOnly
                            className="bg-light"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label className="font-medium">Edad</Form.Label>
                        <Form.Control
                          type="number"
                          name="age"
                          value={formData.age}
                          onChange={handleInputChange}
                          placeholder="Tu edad"
                          className="bg-light"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* Información profesional */}
                  <h5 className="font-semibold mb-4">Experiencia Profesional</h5>
                  <Row className="mb-4">
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label className="font-medium">Experiencia en Marketing</Form.Label>
                        <Form.Select
                          name="marketingExperience"
                          value={formData.marketingExperience}
                          onChange={handleInputChange}
                          className="bg-light"
                        >
                          <option value="">Selecciona tu nivel de experiencia</option>
                          <option value="beginner">Principiante (0-1 años)</option>
                          <option value="intermediate">Intermedio (1-3 años)</option>
                          <option value="advanced">Avanzado (3-5 años)</option>
                          <option value="expert">Experto (5+ años)</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label className="font-medium">Industria</Form.Label>
                        <Form.Select
                          name="industry"
                          value={formData.industry}
                          onChange={handleInputChange}
                          className="bg-light"
                        >
                          <option value="">Selecciona tu industria</option>
                          <option value="technology">Tecnología</option>
                          <option value="retail">Comercio Minorista</option>
                          <option value="healthcare">Salud</option>
                          <option value="education">Educación</option>
                          <option value="finance">Finanzas</option>
                          <option value="other">Otra</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* Objetivos y Plataformas */}
                  <h5 className="font-semibold mb-4">Objetivos y Plataformas</h5>
                  <div className="mb-4">
                    <Form.Label className="font-medium d-block mb-3">Objetivos Principales</Form.Label>
                    <div className="grid grid-cols-2 gap-3">
                      {[
                        { value: 'awareness', label: 'Reconocimiento de Marca' },
                        { value: 'leads', label: 'Generación de Leads' },
                        { value: 'sales', label: 'Aumentar Ventas' },
                        { value: 'traffic', label: 'Tráfico Web' }
                      ].map(goal => (
                        <div
                          key={goal.value}
                          onClick={() => handleMultiSelect('mainGoals', goal.value)}
                          className={`p-3 rounded-lg cursor-pointer border transition-all ${
                            formData.mainGoals.includes(goal.value)
                              ? 'border-blue-500 bg-blue-50'
                              : 'border-gray-200 hover:border-blue-200'
                          }`}
                        >
                          <div className="flex items-center">
                            <input
                              type="checkbox"
                              checked={formData.mainGoals.includes(goal.value)}
                              onChange={() => {}}
                              className="form-checkbox h-5 w-5 text-blue-500"
                            />
                            <span className="ml-2 font-medium">{goal.label}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="mb-4">
                    <Form.Label className="font-medium d-block mb-3">Plataformas Preferidas</Form.Label>
                    <div className="grid grid-cols-2 gap-3">
                      {[
                        { value: 'google', label: 'Google Ads' },
                        { value: 'meta', label: 'Meta Ads' },
                        { value: 'linkedin', label: 'LinkedIn Ads' },
                        { value: 'tiktok', label: 'TikTok Ads' }
                      ].map(platform => (
                        <div
                          key={platform.value}
                          onClick={() => handleMultiSelect('preferredPlatforms', platform.value)}
                          className={`p-3 rounded-lg cursor-pointer border transition-all ${
                            formData.preferredPlatforms.includes(platform.value)
                              ? 'border-blue-500 bg-blue-50'
                              : 'border-gray-200 hover:border-blue-200'
                          }`}
                        >
                          <div className="flex items-center">
                            <input
                              type="checkbox"
                              checked={formData.preferredPlatforms.includes(platform.value)}
                              onChange={() => {}}
                              className="form-checkbox h-5 w-5 text-blue-500"
                            />
                            <span className="ml-2 font-medium">{platform.label}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Presupuesto y Equipo */}
                  <h5 className="font-semibold mb-4">Presupuesto y Equipo</h5>
                  <Row className="mb-4">
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label className="font-medium">Presupuesto Mensual</Form.Label>
                        <Form.Select
                          name="monthlyBudget"
                          value={formData.monthlyBudget}
                          onChange={handleInputChange}
                          className="bg-light"
                        >
                          <option value="">Selecciona un rango</option>
                          <option value="0-500">$0 - $500</option>
                          <option value="501-2000">$501 - $2,000</option>
                          <option value="2001-5000">$2,001 - $5,000</option>
                          <option value="5001-10000">$5,001 - $10,000</option>
                          <option value="10001+">$10,001+</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <div className="mb-3">
                          <Form.Check
                            type="switch"
                            id="hasTeam"
                            label="¿Tienes un equipo de marketing?"
                            name="hasTeam"
                            checked={formData.hasTeam}
                            onChange={handleInputChange}
                          />
                        </div>
                        {formData.hasTeam && (
                          <Form.Control
                            type="number"
                            name="teamSize"
                            value={formData.teamSize}
                            onChange={handleInputChange}
                            placeholder="Número de personas"
                            className="bg-light"
                          />
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* Preferencias */}
                  <h5 className="font-semibold mb-4">Preferencias</h5>
                  <Row className="mb-4">
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label className="font-medium">Idioma</Form.Label>
                        <Form.Select
                          name="language"
                          value={formData.language}
                          onChange={handleInputChange}
                          className="bg-light"
                        >
                          <option value="es">Español</option>
                          <option value="en">English</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label className="font-medium">Tema</Form.Label>
                        <div className="d-flex gap-3">
                          {['light', 'dark'].map(theme => (
                            <Button
                              key={theme}
                              variant={formData.theme === theme ? 'primary' : 'outline-primary'}
                              onClick={() => handleInputChange({
                                target: { name: 'theme', value: theme }
                              })}
                              className="flex-1"
                            >
                              {theme === 'light' ? 'Claro' : 'Oscuro'}
                            </Button>
                          ))}
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* Notificaciones */}
                  <h5 className="font-semibold mb-4">Notificaciones</h5>
                  <div className="space-y-3 mb-4">
                    {[
                      { key: 'email', label: 'Notificaciones por email', icon: Mail },
                      { key: 'push', label: 'Notificaciones push', icon: Bell },
                      { key: 'marketing', label: 'Emails de marketing', icon: Globe }
                    ].map(({ key, label, icon: Icon }) => (
                      <div key={key} className="flex items-center justify-between p-3 bg-light rounded">
                        <div className="flex items-center">
                          <Icon size={18} className="text-primary mr-3" />
                          <span className="font-medium">{label}</span>
                        </div>
                        <Form.Check
                          type="switch"
                          id={`switch-${key}`}
                          checked={formData.notifications[key]}
                          onChange={() => handleNotificationChange(key)}
                        />
                      </div>
                    ))}
                  </div>

                  {/* Botones de acción */}
                  <div className="flex justify-end gap-3 pt-4 border-t">
                    <Button
                      variant="outline-secondary"
                      onClick={() => navigate(-1)}
                      disabled={loading}
                    >
                      Cancelar
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <Spinner size="sm" className="mr-2" />
                          Guardando...
                        </>
                      ) : (
                        <>
                          <Save size={18} className="mr-2" />
                          Guardar Cambios
                        </>
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          );

      case 'security':
        return (
          <div className="mb-4">
            <h4 className="mb-4 text-primary">Seguridad Avanzada</h4>
            
            {/* Cambio de contraseña */}
            <div className="bg-light p-4 rounded-lg mb-4">
              <h5 className="mb-3">Cambiar Contraseña</h5>
              <Row className="g-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Contraseña Actual</Form.Label>
                    <Form.Control
                      type="password"
                      name="currentPassword"
                      value={formData.currentPassword}
                      onChange={handleInputChange}
                      isInvalid={!!errors.currentPassword}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.currentPassword}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Nueva Contraseña</Form.Label>
                    <Form.Control
                      type="password"
                      name="newPassword"
                      value={formData.newPassword}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Confirmar Nueva Contraseña</Form.Label>
                    <Form.Control
                      type="password"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleInputChange}
                      isInvalid={!!errors.confirmPassword}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmPassword}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <div className="mt-3">
                <Button 
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <Spinner size="sm" className="me-2" />
                      Actualizando...
                    </>
                  ) : (
                    'Actualizar Contraseña'
                  )}
                </Button>
              </div>
            </div>

            {/* Autenticación de dos factores */}
            <div className="bg-light p-4 rounded-lg mb-4">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5 className="mb-2">Autenticación de dos factores</h5>
                  <p className="text-muted mb-0">
                    Agrega una capa adicional de seguridad a tu cuenta
                  </p>
                </div>
                <Button 
                  variant={twoFactorEnabled ? "success" : "primary"}
                  onClick={twoFactorEnabled ? undefined : initiate2FA}
                  disabled={loading}
                >
                  <Shield size={18} className="me-2" />
                  {twoFactorEnabled ? 'Activado' : 'Activar 2FA'}
                </Button>
              </div>
            </div>

            {/* Sesiones activas */}
            <div className="bg-light p-4 rounded-lg mb-4">
              <h5 className="mb-3">Sesiones Activas</h5>
              <div className="d-flex flex-column gap-3">
                {activeSessions.length > 0 ? (
                  activeSessions.map((session) => (
                    <div key={session.id} className="d-flex justify-content-between align-items-center p-3 bg-white rounded">
                      <div className="d-flex align-items-center">
                        <Smartphone size={18} className="text-primary me-3" />
                        <div>
                          <p className="mb-0 fw-medium">
                            {session.deviceName} - {session.browser}
                          </p>
                          <small className="text-muted">
                            {session.location} - {session.lastActive}
                          </small>
                        </div>
                      </div>
                      <Button 
                        variant="outline-danger" 
                        size="sm"
                        onClick={() => handleEndSession(session.id)}
                        disabled={loading}
                      >
                        {loading && selectedSessionId === session.id ? (
                          <Spinner size="sm" className="me-2" />
                        ) : null}
                        Cerrar Sesión
                      </Button>
                    </div>
                  ))
                ) : (
                  <div className="text-center text-muted p-4">
                    No hay sesiones activas para mostrar
                  </div>
                )}
              </div>
            </div>

            {/* Historial de actividad */}
            <div className="bg-light p-4 rounded-lg">
              <h5 className="mb-3">Historial de Actividad</h5>
              <div className="d-flex flex-column gap-2">
                {activityHistory.map((activity, index) => (
                  <div key={index} className="d-flex justify-content-between align-items-center p-3 bg-white rounded">
                    <div className="d-flex align-items-center">
                      <History size={18} className="text-primary me-3" />
                      <div>
                        <p className="mb-0">{activity.action}</p>
                        <small className="text-muted">{activity.location}</small>
                      </div>
                    </div>
                    <small className="text-muted">{activity.date}</small>
                  </div>
                ))}
              </div>
            </div>
          </div>
        );

        case 'billing':
          return (
            <div className="mb-4">
              <h4 className="mb-4 text-primary">Facturación</h4>
              
              {/* Plan Actual */}
              <div className="bg-light p-4 rounded-lg mb-4">
                <h5 className="mb-3">Plan Actual</h5>
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 className="mb-1">{subscriptionStatus === 'free' ? 'Plan Gratuito' : 'Plan Premium'}</h6>
                    <p className="text-muted mb-0">
                      {subscriptionStatus === 'free' ? 'Funcionalidades básicas' : 'Acceso completo'}
                    </p>
                  </div>
                  <Button variant="primary">
                    {subscriptionStatus === 'free' ? 'Actualizar a Premium' : 'Gestionar Plan'}
                  </Button>
                </div>
              </div>
        
              {/* Métodos de Pago */}
              <div className="mb-4">
                <h5 className="mb-3">Métodos de Pago</h5>
                <div className="bg-light p-4 rounded-lg">
                  <div id="mercadopago-button"></div>
                  <div className="mercadopago-button">
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      'Cargando opciones de pago...'
                    )}
                  </div>
                  <Button 
                    variant="outline-primary" 
                    className="mt-3"
                    disabled={!MERCADOPAGO_PUBLIC_KEY}
                  >
                    <CreditCard size={18} className="me-2" />
                    Agregar método de pago
                  </Button>
                </div>
              </div>
        
              {/* Historial de Facturación */}
              <div>
                <h5 className="mb-3">Historial de Facturación</h5>
                <Table responsive className="bg-white">
                  <thead>
                    <tr>
                      <th>Fecha</th>
                      <th>Descripción</th>
                      <th>Monto</th>
                      <th>Estado</th>
                      <th>Factura</th>
                    </tr>
                  </thead>
                  <tbody>
                    {billingHistory.map((item, index) => (
                      <tr key={index}>
                        <td>{new Date(item.date).toLocaleDateString()}</td>
                        <td>{item.description}</td>
                        <td>${item.amount}</td>
                        <td>
                          <Badge bg={item.status === 'paid' ? 'success' : 'warning'}>
                            {item.status === 'paid' ? 'Pagado' : 'Pendiente'}
                          </Badge>
                        </td>
                        <td>
                          <Button variant="link" size="sm">
                            <FileText size={18} />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          );

      case 'help':
        return (
          <div className="mb-4">
            <h4 className="mb-4 text-primary">Centro de Ayuda</h4>
            
            {/* Preguntas frecuentes */}
            <div className="bg-light p-4 rounded-lg mb-4">
              <h5 className="mb-3">Preguntas Frecuentes</h5>
              <Accordion>
                {[
                  { 
                    title: '¿Cómo cambio mi contraseña?',
                    content: 'Para cambiar tu contraseña, ve a la sección de Seguridad y sigue los pasos indicados.'
                  },
                  {
                    title: '¿Cómo actualizo mi plan?',
                    content: 'Dirígete a la sección de Facturación donde encontrarás las opciones de planes disponibles.'
                  },
                    {
                      title: '¿Cómo funciona la autenticación de dos factores?',
                      content: 'La autenticación de dos factores añade una capa extra de seguridad requiriendo un código además de tu contraseña.'
                    }
                  ].map((faq, index) => (
                    <Accordion.Item key={index} eventKey={index.toString()}>
                      <Accordion.Header>{faq.title}</Accordion.Header>
                      <Accordion.Body>{faq.content}</Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>

              {/* Documentación */}
              <div className="bg-light p-4 rounded-lg mb-4">
                <h5 className="mb-3">Documentación</h5>
                <Row className="g-3">
                  {[
                    { 
                      title: 'Guía de inicio',
                      description: 'Aprende los conceptos básicos',
                      icon: BookOpen 
                    },
                    { 
                      title: 'API Documentation',
                      description: 'Documentación técnica completa',
                      icon: FileText 
                    }
                  ].map((doc, index) => (
                    <Col md={6} key={index}>
                      <div className="bg-white p-3 rounded d-flex align-items-center">
                        <doc.icon size={24} className="text-primary me-3" />
                        <div className="flex-grow-1">
                          <h6 className="mb-1">{doc.title}</h6>
                          <p className="text-muted mb-2 small">{doc.description}</p>
                          <Button variant="link" className="p-0">
                            Ver documentación
                            <ExternalLink size={16} className="ms-2" />
                          </Button>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>

              {/* Soporte */}
              <div className="bg-light p-4 rounded-lg">
                <h5 className="mb-3">Contactar Soporte</h5>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Asunto</Form.Label>
                    <Form.Control type="text" placeholder="¿En qué podemos ayudarte?" />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Mensaje</Form.Label>
                    <Form.Control as="textarea" rows={4} placeholder="Describe tu problema o pregunta" />
                  </Form.Group>
                  <Button variant="primary">
                    Enviar mensaje
                  </Button>
                </Form>
              </div>
            </div>
          );

        default:
          return null;
      }
    };
    // Loading spinner
    if (loading && !formData.name) {
      return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Cargando...</span>
          </Spinner>
        </div>
      );
    }

    return (
      <div className="min-h-screen bg-gray-50">
        {/* Sidebar */}
        <aside 
          className={`fixed inset-y-0 left-0 z-30 w-64 bg-white shadow-lg transform transition-transform duration-300 ease-in-out 
            ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}`}
        >
          <Sidebar
            isOpen={isSidebarOpen}
            onClose={() => setIsSidebarOpen(false)}
            user={auth.user}
            campaigns={[]}
            selectedCampaign={null}
            setSelectedCampaign={() => {}}
            setIsNewCampaignModalOpen={() => {}}
            handleLogout={() => {}}
          />
        </aside>
    
        {/* Main Content */}
        <div className="lg:pl-64">

        <main className="py-6 ">


            <div className="px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
              {/* Mobile Header */}
              <div className="lg:hidden mb-6">
                <button
                  onClick={() => setIsSidebarOpen(true)}
                  className="p-2 rounded-lg bg-white shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <Menu className="h-6 w-6 text-gray-600" />
                </button>
              </div>
    
              {/* Profile Header */}
              <div className="bg-gradient-to-r from-blue-600 to-blue-700 rounded-2xl shadow-xl mb-6 ">
                <div className="px-6 py-8 sm:px-8 sm:py-10">
                  <div className="flex flex-col lg:flex-row items-center gap-6">
                    {/* Profile Image */}
                    <div className="relative flex-shrink-0">
                      <div className="w-32 h-32 lg:w-40 lg:h-40 rounded-full  border-4 border-white/20 shadow-xl">
                        <img
                          src={profileImage || "https://via.placeholder.com/150"}
                          alt="Profile"
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <label className="absolute bottom-0 right-0 p-2 bg-white rounded-full shadow-lg cursor-pointer 
                        hover:scale-105 transition-transform duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500">
                        <Camera className="h-5 w-5 text-blue-600" />
                        <input
                          type="file"
                          className="hidden"
                          accept="image/*"
                          onChange={handleImageUpload}
                          disabled={loading}
                        />
                      </label>
                    </div>
    
                    {/* Profile Info */}
                    <div className="text-center lg:text-left">
                      <h1 className="text-3xl lg:text-4xl font-bold text-white mb-2">
                        {formData.name || 'Usuario'}
                      </h1>
                      <p className="text-xl text-blue-100 mb-4">
                        {formData.email || 'email@ejemplo.com'}
                      </p>
                      <div className="flex flex-wrap items-center gap-3 justify-center lg:justify-start">
                        <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-500 text-white">
                        <CheckCircleIcon className="w-4 h-4 mr-1" />

                          Cuenta Verificada
                        </span>
                        <span className="text-blue-100">
                          Miembro desde {new Date().getFullYear()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
    
              {/* Notifications */}
              {notification && (
                <div 
                  className={`mb-6 rounded-lg shadow-md transition-all duration-300 transform animate-fade-in
                    ${notification.type === 'success' ? 'bg-green-50' : 'bg-red-50'}`}
                >
                  <div className="p-4 flex items-center gap-3">
                    {notification.type === 'success' ? (
                      <CheckCircleIcon className="w-4 h-4 mr-1" />

                    ) : (
                      <XCircleIcon className="h-5 w-5 text-red-500" />

                    )}
                    <p className={`text-sm ${notification.type === 'success' ? 'text-green-800' : 'text-red-800'}`}>
                      {notification.message}
                    </p>
                    <button
                      onClick={() => setNotification(null)}
                      className="ml-auto text-gray-400 hover:text-gray-600 focus:outline-none"
                    >
                      <X className="h-4 w-4" />
                    </button>
                  </div>
                </div>
              )}
    
              {/* Main Grid */}
              <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
                {/* Navigation Sidebar */}
                <div className="lg:col-span-3">
                  <div className="bg-white rounded-xl shadow-sm p-4">
                    <h3 className="text-lg font-semibold text-gray-800 mb-4">
                      Configuración
                    </h3>
                    <nav className="space-y-2">
                      {[
                        { id: 'personal', label: 'Información Personal', icon: User },
                        { id: 'security', label: 'Seguridad', icon: Lock },
                        { id: 'billing', label: 'Facturación', icon: CreditCard },
                        { id: 'help', label: 'Ayuda', icon: HelpCircle }
                      ].map(item => (
                        <button
                          key={item.id}
                          onClick={() => setActiveTab(item.id)}
                          className={`w-full flex items-center gap-3 px-4 py-3 rounded-lg transition-all duration-200
                            ${activeTab === item.id 
                              ? 'bg-blue-500 text-white shadow-md' 
                              : 'text-gray-600 hover:bg-gray-50'}`}
                        >
                          <item.icon className="h-5 w-5" />
                          <span className="font-medium">{item.label}</span>
                        </button>
                      ))}
                    </nav>
                  </div>
                </div>
    
                {/* Content Area */}
                <div className="lg:col-span-9">
                  <div className="bg-white rounded-xl shadow-sm">
                    <div className="p-6">
                      {renderTabContent()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
    
        {/* Modales */}
        {/* Modal 2FA */}
        <Modal 
          show={showQRModal} 
          onHide={() => setShowQRModal(false)}
          className="relative z-50"
        >
          <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
          <div className="fixed inset-0 flex items-center justify-center p-4">
            <div className="bg-white rounded-xl shadow-xl w-full max-w-md transform transition-all">
              <div className="px-6 py-4 border-b border-gray-200">
                <h3 className="text-xl font-semibold text-gray-800">
                  Configurar Autenticación de Dos Factores
                </h3>
              </div>
              <div className="p-6">
                <div className="text-center mb-6">
                  <img src={qrCode} alt="QR Code" className="mx-auto" />
                </div>
                <div className="space-y-4">
                  <label className="block">
                    <span className="text-gray-700 font-medium">Código de Verificación</span>
                    <input
                      type="text"
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                      placeholder="Ingresa el código de 6 dígitos"
                      className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </label>
                </div>
              </div>
              <div className="px-6 py-4 bg-gray-50 rounded-b-xl flex justify-end gap-3">
                <button
                  onClick={() => setShowQRModal(false)}
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  Cancelar
                </button>
                <button
                  onClick={verify2FA}
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                >
                  Verificar
                </button>
              </div>
            </div>
          </div>
        </Modal>
    
        {/* Modal de cambios no guardados */}
        <Modal 
          show={showUnsavedChangesModal} 
          onHide={() => setShowUnsavedChangesModal(false)}
          className="relative z-50"
        >
          <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
          <div className="fixed inset-0 flex items-center justify-center p-4">
            <div className="bg-white rounded-xl shadow-xl w-full max-w-md">
              <div className="px-6 py-4 border-b border-gray-200">
                <h3 className="text-xl font-semibold text-gray-800">
                  Cambios no guardados
                </h3>
              </div>
              <div className="p-6">
                <p className="text-gray-600">
                  ¿Estás seguro de que quieres salir? Los cambios que has realizado se perderán.
                </p>
              </div>
              <div className="px-6 py-4 bg-gray-50 rounded-b-xl flex justify-end gap-3">
                <button
                  onClick={() => setShowUnsavedChangesModal(false)}
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  Cancelar
                </button>
                <button
                  onClick={handleDiscardChanges}
                  className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                >
                  Salir sin guardar
                </button>
              </div>
            </div>
          </div>
        </Modal>
    
        {/* Modal de confirmación para terminar sesiones */}
        <Modal 
          show={showEndSessionModal} 
          onHide={() => {
            setShowEndSessionModal(false);
            setSelectedSessionId(null);
          }}
          className="relative z-50"
        >
          <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
          <div className="fixed inset-0 flex items-center justify-center p-4">
            <div className="bg-white rounded-xl shadow-xl w-full max-w-md">
              <div className="px-6 py-4 border-b border-gray-200">
                <h3 className="text-xl font-semibold text-gray-800">
                  Confirmar cierre de sesión
                </h3>
              </div>
              <div className="p-6">
                <p className="text-gray-600">
                  ¿Estás seguro de que quieres cerrar esta sesión? El dispositivo necesitará volver a iniciar sesión.
                </p>
              </div>
              <div className="px-6 py-4 bg-gray-50 rounded-b-xl flex justify-end gap-3">
                <button
                  onClick={() => {
                    setShowEndSessionModal(false);
                    setSelectedSessionId(null);
                  }}
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  Cancelar
                </button>
                <button
                  onClick={executeEndSession}
                  disabled={loading}
                  className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors
                    disabled:bg-red-300 disabled:cursor-not-allowed flex items-center gap-2"
                >
                  {loading ? (
                    <>
                      <Spinner className="w-4 h-4" />
                      <span>Cerrando...</span>
                    </>
                  ) : (
                    'Cerrar Sesión'
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  };

  export default Profile;