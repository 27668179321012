// GoogleAuthHandler.js
import { useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';

const GoogleAuthHandler = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    
    if (token) {
      console.log("Token recibido:", token);
      login(token);
      navigate('/dashboard');
    }
  }, [location, login, navigate]);

  return null;
};

export default GoogleAuthHandler;