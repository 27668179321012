import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import { useAuth } from '../components/context/AuthContext';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const ProtectedLayout = () => {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [isNewCampaignModalOpen, setIsNewCampaignModalOpen] = useState(false);

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const fetchCampaigns = async () => {
    try {
      const response = await axios.get(`${API_URL}/campaigns`, {
        headers: { Authorization: `Bearer ${auth.token}` }
      });
      setCampaigns(response.data);
    } catch (error) {
      console.error('Error fetching campaigns:', error);
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className="flex h-screen">
      <Sidebar 
        campaigns={campaigns}
        selectedCampaign={selectedCampaign}
        setSelectedCampaign={setSelectedCampaign}
        setIsNewCampaignModalOpen={setIsNewCampaignModalOpen}
        handleLogout={handleLogout}
        onExpandedChange={setSidebarExpanded}
      />
      <main className={`flex-1 transition-all duration-300 ease-in-out
        ${sidebarExpanded ? 'lg:pl-64' : 'lg:pl-14'}`}>
        <Outlet context={{ 
          campaigns,
          setCampaigns,
          fetchCampaigns,
          isNewCampaignModalOpen,
          setIsNewCampaignModalOpen
        }}/>
      </main>
    </div>
  );
};

export default ProtectedLayout;