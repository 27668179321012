import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import { jwtDecode } from 'jwt-decode';  // Corrected import

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        return { token, user: decoded };
      } catch (error) {
        localStorage.removeItem('token');
        return { token: null, user: null };
      }
    }
    return { token: null, user: null };
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    setAuth({ token: null, user: null });
  }, []);

  const login = useCallback((token) => {
    try {
      localStorage.setItem('token', token);
      const decoded = jwtDecode(token);
      setAuth({ token, user: decoded });
      console.log('Login successful:', decoded);
    } catch (error) {
      console.error('Error en login:', error);
      logout();
    }
  }, [logout]);

  const updateUser = useCallback((userData) => {
    setAuth((prevAuth) => ({
      ...prevAuth,
      user: { ...prevAuth.user, ...userData },
    }));
  }, []);

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        login,
        logout,
        updateUser,
        isAuthenticated: !!auth.token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth debe ser usado dentro de un AuthProvider');
  }
  return context;
};
