import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../components/context/AuthContext';
import { Card, Container, Row, Col, Button, Form } from 'react-bootstrap';
import { 
  BarChart2, 
  TrendingUp, 
  Users, 
  DollarSign,
  Calendar,
  Filter
} from 'lucide-react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell
} from 'recharts';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const Analytics = () => {
  const { auth } = useContext(AuthContext);
  const [dateRange, setDateRange] = useState('7d');
  const [platformFilter, setPlatformFilter] = useState('all');
  const [performanceData, setPerformanceData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAnalyticsData();
  }, [dateRange, platformFilter]);

  const fetchAnalyticsData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_URL}/analytics`, {
        headers: { Authorization: `Bearer ${auth.token}` },
        params: { dateRange, platform: platformFilter }
      });
      setPerformanceData(response.data);
    } catch (error) {
      console.error('Error fetching analytics:', error);
    } finally {
      setLoading(false);
    }
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  // Sample data - replace with actual data from your API
  const performanceMetrics = {
    impressions: 125000,
    clicks: 2500,
    conversions: 150,
    ctr: 2.0,
    costPerClick: 0.75,
    conversionRate: 6.0,
    roi: 2.5
  };

  const platformData = [
    { name: 'Google Ads', value: 60 },
    { name: 'Meta Ads', value: 40 }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <div className="bg-white border-b">
        <Container fluid className="py-4">
          <Row className="align-items-center">
            <Col>
              <h1 className="text-2xl font-semibold">Analytics</h1>
              <p className="text-gray-600">Análisis detallado de campañas</p>
            </Col>
            <Col xs="auto">
              <div className="flex space-x-2">
                <Form.Select 
                  value={dateRange}
                  onChange={(e) => setDateRange(e.target.value)}
                  className="w-auto"
                >
                  <option value="7d">Últimos 7 días</option>
                  <option value="30d">Últimos 30 días</option>
                  <option value="90d">Últimos 90 días</option>
                </Form.Select>
                <Form.Select
                  value={platformFilter}
                  onChange={(e) => setPlatformFilter(e.target.value)}
                  className="w-auto"
                >
                  <option value="all">Todas las plataformas</option>
                  <option value="google">Google Ads</option>
                  <option value="meta">Meta Ads</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container fluid className="py-6">
        {/* Metrics Overview */}
        <Row className="mb-4">
          {[
            { 
              title: 'Impresiones',
              value: performanceMetrics.impressions.toLocaleString(),
              icon: Users,
              color: 'blue'
            },
            {
              title: 'Clicks',
              value: performanceMetrics.clicks.toLocaleString(),
              icon: TrendingUp,
              color: 'green'
            },
            {
              title: 'Conversiones',
              value: performanceMetrics.conversions.toLocaleString(),
              icon: BarChart2,
              color: 'purple'
            },
            {
              title: 'ROI',
              value: `${performanceMetrics.roi}x`,
              icon: DollarSign,
              color: 'orange'
            }
          ].map((metric, index) => (
            <Col key={index} md={3} className="mb-4">
              <Card className="shadow-sm h-100">
                <Card.Body>
                  <div className="flex items-center justify-between">
                    <div>
                      <div className="text-gray-600 text-sm">{metric.title}</div>
                      <div className="text-2xl font-semibold mt-1">{metric.value}</div>
                    </div>
                    <div className={`p-3 rounded-full bg-${metric.color}-100`}>
                      <metric.icon className={`w-6 h-6 text-${metric.color}-600`} />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Performance Charts */}
        <Row>
          <Col lg={8} className="mb-4">
            <Card className="shadow-sm">
              <Card.Body>
                <h5 className="font-semibold mb-4">Rendimiento en el Tiempo</h5>
                <div style={{ height: '400px' }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={performanceData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis />
                      <Tooltip />
                      <Line type="monotone" dataKey="impressions" stroke="#8884d8" />
                      <Line type="monotone" dataKey="clicks" stroke="#82ca9d" />
                      <Line type="monotone" dataKey="conversions" stroke="#ffc658" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={4} className="mb-4">
            <Card className="shadow-sm">
              <Card.Body>
                <h5 className="font-semibold mb-4">Distribución por Plataforma</h5>
                <div style={{ height: '400px' }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={platformData}
                        cx="50%"
                        cy="50%"
                        outerRadius={120}
                        fill="#8884d8"
                        dataKey="value"
                        label={({name, value}) => `${name}: ${value}%`}
                      >
                        {platformData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Performance Metrics */}
        <Row>
          <Col>
            <Card className="shadow-sm">
              <Card.Body>
                <h5 className="font-semibold mb-4">Métricas Detalladas</h5>
                <div className="overflow-x-auto">
                  <table className="min-w-full">
                    <thead></thead>
                    <thead>
                      <tr className="bg-gray-50">
                        <th className="px-4 py-2 text-left">Campaña</th>
                        <th className="px-4 py-2 text-left">Plataforma</th>
                        <th className="px-4 py-2 text-right">Impresiones</th>
                        <th className="px-4 py-2 text-right">Clicks</th>
                        <th className="px-4 py-2 text-right">CTR</th>
                        <th className="px-4 py-2 text-right">CPC</th>
                        <th className="px-4 py-2 text-right">Conversiones</th>
                        <th className="px-4 py-2 text-right">Costo/Conv.</th>
                        <th className="px-4 py-2 text-right">ROI</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="9" className="text-center py-4">
                            <div className="flex items-center justify-center">
                              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                            </div>
                          </td>
                        </tr>
                      ) : performanceData.length === 0 ? (
                        <tr>
                          <td colSpan="9" className="text-center py-4 text-gray-500">
                            No hay datos disponibles para el período seleccionado
                          </td>
                        </tr>
                      ) : (
                        performanceData.map((campaign, index) => (
                          <tr key={index} className="border-t hover:bg-gray-50">
                            <td className="px-4 py-3">
                              <div className="font-medium">{campaign.name}</div>
                              <div className="text-sm text-gray-500">{campaign.id}</div>
                            </td>
                            <td className="px-4 py-3">
                              <span className={`px-2 py-1 rounded-full text-sm ${
                                campaign.platform === 'Google Ads' 
                                  ? 'bg-blue-100 text-blue-700'
                                  : 'bg-green-100 text-green-700'
                              }`}>
                                {campaign.platform}
                              </span>
                            </td>
                            <td className="px-4 py-3 text-right">
                              {campaign.impressions.toLocaleString()}
                              <div className="text-xs text-gray-500">
                                {campaign.impressions_change > 0 ? '+' : ''}
                                {campaign.impressions_change}%
                              </div>
                            </td>
                            <td className="px-4 py-3 text-right">
                              {campaign.clicks.toLocaleString()}
                              <div className="text-xs text-gray-500">
                                {campaign.clicks_change > 0 ? '+' : ''}
                                {campaign.clicks_change}%
                              </div>
                            </td>
                            <td className="px-4 py-3 text-right">
                              {campaign.ctr.toFixed(2)}%
                              <div className="text-xs text-gray-500">
                                {campaign.ctr_change > 0 ? '+' : ''}
                                {campaign.ctr_change}%
                              </div>
                            </td>
                            <td className="px-4 py-3 text-right">
                              ${campaign.cpc.toFixed(2)}
                              <div className="text-xs text-gray-500">
                                {campaign.cpc_change > 0 ? '+' : ''}
                                {campaign.cpc_change}%
                              </div>
                            </td>
                            <td className="px-4 py-3 text-right">
                              {campaign.conversions.toLocaleString()}
                              <div className="text-xs text-gray-500">
                                {campaign.conversions_change > 0 ? '+' : ''}
                                {campaign.conversions_change}%
                              </div>
                            </td>
                            <td className="px-4 py-3 text-right">
                              ${campaign.cost_per_conversion.toFixed(2)}
                              <div className="text-xs text-gray-500">
                                {campaign.cost_per_conversion_change > 0 ? '+' : ''}
                                {campaign.cost_per_conversion_change}%
                              </div>
                            </td>
                            <td className="px-4 py-3 text-right">
                              <span className={`font-medium ${
                                campaign.roi >= 2 ? 'text-green-600' : 
                                campaign.roi >= 1 ? 'text-yellow-600' : 'text-red-600'
                              }`}>
                                {campaign.roi.toFixed(2)}x
                              </span>
                              <div className="text-xs text-gray-500">
                                {campaign.roi_change > 0 ? '+' : ''}
                                {campaign.roi_change}%
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                    <tfoot className="bg-gray-50 font-medium">
                      <tr>
                        <td className="px-4 py-3" colSpan="2">Total</td>
                        <td className="px-4 py-3 text-right">
                          {performanceData.reduce((sum, camp) => sum + camp.impressions, 0).toLocaleString()}
                        </td>
                        <td className="px-4 py-3 text-right">
                          {performanceData.reduce((sum, camp) => sum + camp.clicks, 0).toLocaleString()}
                        </td>
                        <td className="px-4 py-3 text-right">
                          {(performanceData.reduce((sum, camp) => sum + camp.clicks, 0) / 
                            performanceData.reduce((sum, camp) => sum + camp.impressions, 0) * 100).toFixed(2)}%
                        </td>
                        <td className="px-4 py-3 text-right">
                          ${(performanceData.reduce((sum, camp) => sum + (camp.clicks * camp.cpc), 0) /
                            performanceData.reduce((sum, camp) => sum + camp.clicks, 0)).toFixed(2)}
                        </td>
                        <td className="px-4 py-3 text-right">
                          {performanceData.reduce((sum, camp) => sum + camp.conversions, 0).toLocaleString()}
                        </td>
                        <td className="px-4 py-3 text-right">
                          ${(performanceData.reduce((sum, camp) => sum + (camp.conversions * camp.cost_per_conversion), 0) /
                            performanceData.reduce((sum, camp) => sum + camp.conversions, 0)).toFixed(2)}
                        </td>
                        <td className="px-4 py-3 text-right">
                          {(performanceData.reduce((sum, camp) => sum + camp.roi, 0) / 
                            performanceData.length).toFixed(2)}x
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Analytics;