import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  MessageSquare, BarChart2, Link as LinkIcon, Plus, Globe, 
  DollarSign, LogOut, Menu, ChevronRight, User, Pin, 
  Search, Circle, X 
} from 'lucide-react';
import { useAuth } from '../components/context/AuthContext';

const Sidebar = ({ 
  campaigns = [], 
  selectedCampaign, 
  setSelectedCampaign, 
  setIsNewCampaignModalOpen, 
  handleLogout,
  onExpandedChange 
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { auth } = useAuth();

  const [screenSize, setScreenSize] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: true
  });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setScreenSize({
        isMobile: width < 640,
        isTablet: width >= 640 && width < 1024,
        isDesktop: width >= 1024
      });
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (screenSize.isMobile || screenSize.isTablet) {
      setIsMobileOpen(false);
      setShowProfileMenu(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (screenSize.isDesktop) {
      onExpandedChange?.(isPinned || isHovered);
    }
  }, [isPinned, isHovered, screenSize.isDesktop, onExpandedChange]);

  const menuItems = [
    { 
      icon: MessageSquare, 
      label: 'Chat IA', 
      path: '/dashboard',
      description: 'Asistente de Marketing'
    },
    { 
      icon: BarChart2, 
      label: 'Analytics', 
      path: '/analytics',
      description: 'Análisis de campañas'
    },
    { 
      icon: LinkIcon, 
      label: 'Conexiones', 
      path: '/accounts',
      description: 'Gestión de cuentas'
    }
  ];

  const isExpanded = screenSize.isDesktop ? (isPinned || isHovered) : isMobileOpen;
  
  const filteredCampaigns = campaigns.filter(campaign => 
    campaign.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getStatusVariant = (status) => {
    const statusClasses = {
      active: 'text-green-500',
      enabled: 'text-green-500',
      paused: 'text-yellow-500',
      removed: 'text-red-500',
      archived: 'text-red-500'
    };
    return statusClasses[status.toLowerCase()] || 'text-gray-500';
  };

  const Backdrop = () => (
    <div 
      className={`fixed inset-0 bg-black/50 transition-opacity z-30 backdrop-blur-[1px]
        ${isExpanded && (screenSize.isMobile || screenSize.isTablet) ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
      onClick={() => setIsMobileOpen(false)}
    />
  );

  return (
    <>
      <Backdrop />
      
      {/* Mobile Toggle Button */}
      <button 
        className="lg:hidden fixed top-4 left-4 z-[60] p-2.5 rounded-xl bg-white/95 shadow-lg
          hover:bg-gray-50 active:bg-gray-100 transition-all duration-200 backdrop-blur-sm
          ring-1 ring-gray-200"
        onClick={() => setIsMobileOpen(!isMobileOpen)}
      >
        {isMobileOpen ? 
          <X size={22} className="text-gray-700" /> : 
          <Menu size={22} className="text-gray-700" />
        }
      </button>

      {/* Sidebar Container */}
      <aside
        className={`fixed inset-y-0 left-0 z-50 transition-all duration-300 ease-in-out
          ${screenSize.isMobile || screenSize.isTablet 
            ? `${isExpanded ? 'translate-x-0 w-[280px]' : '-translate-x-full w-0'}` 
            : `${isExpanded ? 'w-64' : 'w-16'} transform-none`}
          ${isExpanded ? 'shadow-xl' : 'shadow-md'}`}
        onMouseEnter={() => screenSize.isDesktop && setIsHovered(true)}
        onMouseLeave={() => screenSize.isDesktop && setIsHovered(false)}
      >
        <div className="flex flex-col h-full bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 
          overflow-hidden relative">
          {/* Efectos decorativos */}
          <div className="absolute inset-x-0 top-0 h-px bg-gradient-to-r from-transparent via-gray-500/20 to-transparent" />
          <div className="absolute inset-x-0 bottom-0 h-px bg-gradient-to-r from-transparent via-gray-500/20 to-transparent" />
          
         {/* Header Section */}
<div className="p-4 border-b border-gray-700/50 relative">
  <div className="flex items-center justify-between">
    <div className="flex items-center min-w-0">
      {isExpanded ? (
        <div className="relative flex items-center justify-center w-8 h-8">
          <img src="/ch3.png" alt="CH3" className="h-8 relative z-10" />
          <div className="absolute inset-0 bg-blue-500/10 blur-xl rounded-lg" />
        </div>
      ) : (
        <div className="relative flex items-center justify-center w-8 h-8">
          <div className="absolute inset-0 bg-gradient-to-br from-blue-600/20 to-indigo-600/20 
            rounded-lg backdrop-blur-sm ring-1 ring-white/10" />
          <span className="relative z-10 text-sm font-semibold text-white/90 group-hover:text-white 
            transition-colors duration-200">
            CH3
          </span>
        </div>
      )}
    </div>

    {screenSize.isDesktop && (
      <button
        onClick={() => setIsPinned(!isPinned)}
        className={`p-1.5 rounded-lg transition-all duration-300
          ${isPinned 
            ? 'bg-blue-500/20 text-blue-400 ring-1 ring-blue-400/30' 
            : 'text-gray-400 hover:bg-gray-700/80 hover:text-white'}
          ${isExpanded ? 'opacity-100' : 'opacity-0 w-0 overflow-hidden'}`}
      >
        <Pin size={16} className={`transition-transform duration-300 
          ${isPinned ? 'rotate-45' : 'hover:rotate-45'}`} />
      </button>
    )}

    {(screenSize.isMobile || screenSize.isTablet) && (
      <button
        onClick={() => setIsMobileOpen(false)}
        className="p-1.5 rounded-lg text-gray-400 hover:bg-gray-700/80 hover:text-white
          transition-colors duration-200"
      >
        <X size={20} />
      </button>
    )}
  </div>
</div>

          {/* Navigation Menu */}
          <div className="flex-1 flex flex-col min-h-0 pt-2">
            <nav className="px-2 space-y-1">
              {menuItems.map((item) => (
                <button
                  key={item.path}
                  onClick={() => {
                    navigate(item.path);
                    if (screenSize.isMobile || screenSize.isTablet) {
                      setIsMobileOpen(false);
                    }
                  }}
                  className={`relative group flex items-center w-full rounded-lg transition-all duration-200
                    ${location.pathname === item.path 
                      ? 'text-blue-400 bg-blue-500/10' 
                      : 'text-gray-400 hover:text-white hover:bg-gray-700/50'}
                    ${isExpanded ? 'px-3 py-2.5' : 'px-2 py-2.5 justify-center'}`}
                >
                  {location.pathname === item.path && (
                    <div className="absolute inset-y-1 left-0 w-1 bg-blue-500 rounded-r-full" />
                  )}
                  
                  <item.icon size={18} className={`flex-shrink-0 transition-transform duration-200
                    ${!isExpanded && 'group-hover:scale-110'}`} />
                  
                  <div className={`ml-3 text-left transition-all duration-300 min-w-0
                    ${isExpanded ? 'opacity-100' : 'opacity-0 w-0 overflow-hidden'}`}>
                    <div className="font-medium truncate">{item.label}</div>
                    <div className="text-xs text-gray-500 truncate">{item.description}</div>
                  </div>
                  
                  {!isExpanded && (
                    <div className="absolute left-full ml-3 px-2 py-1 bg-gray-800 text-white text-xs
                      rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200
                      pointer-events-none whitespace-nowrap shadow-lg">
                      {item.label}
                    </div>
                  )}
                </button>
              ))}
            </nav>

            {/* Campaigns Section */}
            <div className="mt-4 py-3 border-t border-gray-700/50">
              {isExpanded && (
                <div className="px-4 mb-2">
                  <h3 className="text-xs font-semibold text-gray-400 uppercase tracking-wider">
                    Campañas
                  </h3>
                </div>
              )}

              {isExpanded && (
                <div className="px-4 mb-3">
                  <div className="relative group">
                    <input
                      type="text"
                      placeholder="Buscar campaña..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="w-full px-3 py-1.5 bg-gray-700/50 text-gray-300 rounded-lg text-sm 
                        placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500/50
                        transition-all duration-200"
                    />
                    <Search size={14} className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500
                      group-hover:text-gray-400 transition-colors duration-200" />
                  </div>
                </div>
              )}

              <div className="space-y-1 px-2">
                {filteredCampaigns.map((campaign) => (
                  <button
                    key={campaign.id}
                    onClick={() => {
                      setSelectedCampaign(campaign);
                      if (screenSize.isMobile || screenSize.isTablet) {
                        setIsMobileOpen(false);
                      }
                    }}
                    className={`relative group flex items-center w-full rounded-lg transition-all duration-200
                      ${selectedCampaign?.id === campaign.id 
                        ? 'text-blue-400 bg-blue-500/10' 
                        : 'text-gray-400 hover:text-white hover:bg-gray-700/50'}
                      ${isExpanded ? 'px-3 py-2' : 'py-2 justify-center'}`}
                  >
                    {selectedCampaign?.id === campaign.id && (
                      <div className="absolute inset-y-1 left-0 w-1 bg-blue-500 rounded-r-full" />
                    )}

                    {isExpanded ? (
                      <div className="flex items-center justify-between w-full min-w-0">
                        <span className="font-medium truncate">{campaign.name}</span>
                        <span className={`ml-2 flex-shrink-0 ${getStatusVariant(campaign.status)}`}>
                          <Circle size={8} fill="currentColor" className="drop-shadow-md" />
                        </span>
                      </div>
                    ) : (
                      <>
                        <Circle 
                          size={8} 
                          className={`${getStatusVariant(campaign.status)} drop-shadow-md
                            transition-transform duration-200 group-hover:scale-110`}
                          fill="currentColor"
                        />
                        <div className="absolute left-full ml-3 px-2 py-1 bg-gray-800 text-white text-xs
                          rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200
                          pointer-events-none whitespace-nowrap shadow-lg">
                          {campaign.name}
                        </div>
                      </>
                    )}
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Profile Section */}
          <div className="mt-auto border-t border-gray-700/50">
            <button
              onClick={() => setShowProfileMenu(!showProfileMenu)}
              className={`relative group flex items-center w-full transition-all duration-200
                hover:bg-gray-700/50 
                ${isExpanded ? 'px-4 py-3' : 'justify-center py-3'}`}
            >
              <div className="relative">
                <div className="w-8 h-8 rounded-full bg-gradient-to-br from-blue-500/30 to-indigo-500/30 
                  flex items-center justify-center ring-2 ring-blue-500/20 group-hover:ring-blue-500/30
                  transition-all duration-200">
                  <span className="text-sm font-medium text-white group-hover:text-blue-100
                    transition-colors duration-200">
                    {auth.user?.name?.[0] || 'U'}
                  </span>
                </div>
                <div className="absolute -bottom-0.5 -right-0.5 w-3 h-3 bg-green-500 rounded-full 
                  ring-2 ring-gray-900" />
              </div>
              
              <div className={`ml-3 text-left transition-opacity duration-300 min-w-0
                ${isExpanded ? 'opacity-100' : 'opacity-0 w-0 overflow-hidden'}`}>
                <p className="text-sm font-medium text-gray-300 truncate group-hover:text-white
                  transition-colors duration-200">
                  {auth.user?.name || 'Usuario'}
                </p>
                <p className="text-xs text-gray-500 truncate group-hover:text-gray-400
                  transition-colors duration-200">
                  {auth.user?.email}
                </p>
              </div>
              
              {isExpanded && (
                <ChevronRight
                  size={16}
                  className={`ml-auto transition-transform duration-300 text-gray-500
                    group-hover:text-gray-400 ${showProfileMenu ? 'rotate-90' : ''}`}
                />
              )}

              {!isExpanded && (
                <div className="absolute left-full ml-3 px-2 py-1 bg-gray-800 text-white text-xs
                  rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200
                  pointer-events-none whitespace-nowrap shadow-lg">
                  Perfil de usuario
                </div>
              )}
            </button>

            {/* Profile Dropdown */}
            {showProfileMenu && isExpanded && (
              <div className="absolute bottom-[4.5rem] left-2 right-2 bg-gray-800/95 backdrop-blur-sm
                rounded-lg shadow-xl border border-gray-700/50 overflow-hidden">
                <button
                  onClick={() => {
                    navigate('/profile');
                    setShowProfileMenu(false);
                  }}
                  className="flex items-center w-full px-4 py-3 text-gray-400 
                    hover:bg-gray-700/50 transition-all duration-200 group"
                >
                  <User size={16} className="mr-3 flex-shrink-0 group-hover:text-blue-400
                    transition-colors duration-200" />
                  <span className="text-sm group-hover:text-gray-100 transition-colors duration-200">
                    Perfil
                  </span>
                </button>
                <button
                  onClick={handleLogout}
                  className="flex items-center w-full px-4 py-3 text-red-400 
                    hover:bg-red-500/10 transition-all duration-200 group"
                >
                  <LogOut size={16} className="mr-3 flex-shrink-0 group-hover:text-red-300
                    transition-colors duration-200" />
                  <span className="text-sm group-hover:text-red-300 transition-colors duration-200">
                    Cerrar sesión
                  </span>
                </button>
              </div>
            )}
          </div>

          {/* Nueva Campaña Button */}
          <div className={`absolute bottom-20 left-0 right-0 flex justify-center items-center 
            transition-all duration-300 ${isExpanded ? 'px-4' : 'px-2'}`}>
            <button
              onClick={() => {
                setIsNewCampaignModalOpen(true);
                if (screenSize.isMobile || screenSize.isTablet) {
                  setIsMobileOpen(false);
                }
              }}
              className={`relative group p-2 rounded-lg bg-gradient-to-r from-blue-500/20 to-indigo-500/20 
                backdrop-blur-sm ring-1 ring-white/10 transition-all duration-300
                hover:from-blue-500/30 hover:to-indigo-500/30 hover:ring-white/20
                active:from-blue-500/40 active:to-indigo-500/40
                ${isExpanded ? 'w-full flex items-center gap-2' : 'w-10 flex justify-center'}`}
            >
              <Plus size={18} className="text-blue-400 group-hover:text-blue-300 
                transition-colors duration-200" />
              {isExpanded && (
                <span className="text-sm font-medium text-blue-400 group-hover:text-blue-300
                  transition-colors duration-200">
                  Nueva campaña
                </span>
              )}
              
              {!isExpanded && (
                <div className="absolute left-full ml-3 px-2 py-1 bg-gray-800 text-white text-xs
                  rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200
                  pointer-events-none whitespace-nowrap shadow-lg">
                  Crear nueva campaña
                </div>
              )}
              
              {/* Efecto de brillo */}
              <div className="absolute inset-0 rounded-lg overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/5 to-indigo-500/5" />
                <div className="absolute -inset-x-full top-0 h-px animate-[shimmer_2s_infinite] 
                  bg-gradient-to-r from-transparent via-blue-400/10 to-transparent" />
              </div>
            </button>
          </div>

          {/* Decorative bottom gradient */}
          <div className="absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t from-gray-900 to-transparent 
            pointer-events-none" />
        </div>
      </aside>
    </>
  );
};

export default Sidebar;