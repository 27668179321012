import React, { useState, useEffect, useContext, useRef } from 'react';
import { Check,  } from 'lucide-react';
import { AuthContext } from '../components/context/AuthContext';
import { 
  Search, ThumbsUp, MessageCircle, Share2, Clock, Menu, 
  Monitor, Smartphone, Send, Loader, Sparkles, Wand2, X,
  Globe, DollarSign, Target
} from 'lucide-react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const GooglePreview = ({ campaignPreview }) => (
  <div className="bg-white p-3 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-all duration-200">
    <div className="mb-3 flex items-center gap-2 border rounded-full p-2 shadow-sm bg-gray-50">
      <Search className="w-4 h-4 text-blue-500" />
      <span className="text-sm text-gray-800 flex-1 truncate">
        {campaignPreview.adContent?.keywords?.[0] || `Búsqueda relacionada con ${campaignPreview.name}`}
      </span>
    </div>

    <div className="mb-1 inline-block text-[10px] px-2 py-0.5 bg-[#E5F0FA] text-[#1B5BAD] rounded-sm">
      Anuncio
    </div>

    <div className="space-y-2">
      <div>
        <div className="text-[#1A0DAB] text-base leading-tight cursor-pointer hover:underline">
          {campaignPreview.adContent?.titulos?.[0] || campaignPreview.name} | {campaignPreview.name}
        </div>
        <div className="text-[#006621] text-xs mb-1">
          {campaignPreview.adContent?.urls?.display || "www.tudominio.com/landing"}
        </div>
        <div className="text-xs text-gray-600">
          {campaignPreview.adContent?.descripcion || 
           `Descubre cómo podemos ayudarte con ${campaignPreview.objective}. Presupuestos desde $${campaignPreview.budget}/día.`}
        </div>
      </div>
      
      <div className="grid grid-cols-3 gap-1 text-[#1A0DAB] text-xs pt-2 border-t border-gray-100">
        {(campaignPreview.adContent?.extensiones || ["Contacto", "Ubicaciones", "Reseñas"]).map((extension, index) => (
          <div key={index} className="cursor-pointer hover:underline p-1.5 rounded hover:bg-gray-50 transition-colors">
            {extension}
          </div>
        ))}
      </div>
    </div>
  </div>
);

const MetaPreview = ({ campaignPreview }) => (
  <div className="bg-[#F0F2F5] p-3 rounded-lg">
    <div className="bg-white rounded-lg shadow-sm">
      <div className="p-3">
        <div className="flex items-center gap-2">
          <div className="w-8 h-8 rounded-full bg-gradient-to-br from-blue-500 to-indigo-600 
            flex items-center justify-center text-white font-bold text-sm">
            {campaignPreview.name?.[0] || 'A'}
          </div>
          <div>
            <div className="font-semibold text-sm text-gray-900">
              {campaignPreview.name}
            </div>
            <div className="text-xs text-gray-500 flex items-center gap-1">
              <span>Patrocinado</span> • <Clock className="w-3 h-3" />
            </div>
          </div>
          <Menu className="w-4 h-4 text-gray-500 ml-auto" />
        </div>
        
        <p className="mt-2 text-sm text-gray-800">
          {campaignPreview.adContent?.descripcion || 
           `¿Buscas mejorar tus ${campaignPreview.objective}? Descubre ${campaignPreview.name} y obtén resultados increíbles.`}
        </p>
      </div>

      <div className="aspect-video bg-gradient-to-br from-blue-100 to-indigo-100 relative">
        <img 
          src="/api/placeholder/800/420" 
          alt="Ad Preview" 
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent p-3 
          flex flex-col justify-end">
          <h3 className="text-base font-semibold text-white">
            {campaignPreview.adContent?.titulos?.[0] || campaignPreview.name}
          </h3>
          <p className="text-xs text-white/90 mt-1">
            {campaignPreview.objective}
          </p>
        </div>
      </div>

      <div className="p-3">
        <button className="w-full bg-blue-600 hover:bg-blue-700 text-white rounded text-sm 
          py-2 font-semibold transition-colors">
          {campaignPreview.adContent?.call_to_action || "Más Información"}
        </button>
      </div>

      <div className="px-3 py-1.5 border-t border-gray-100">
        <div className="flex items-center justify-between text-xs text-gray-500">
          <div className="flex items-center gap-1">
            <ThumbsUp className="w-3 h-3" />
            <span>{campaignPreview.adContent?.metricas_estimadas?.alcance || "1.2K"}</span>
          </div>
          <div className="flex gap-2">
            <span>234 comentarios</span>
            <span>45 veces compartido</span>
          </div>
        </div>
      </div>

      <div className="px-3 py-1 border-t border-gray-100">
        <div className="flex justify-between">
          {['Me gusta', 'Comentar', 'Compartir'].map((action, index) => (
            <button
              key={action}
              className="flex-1 py-1.5 text-xs text-gray-500 hover:bg-gray-50 
                rounded flex items-center justify-center gap-1 transition-colors"
            >
              {index === 0 && <ThumbsUp className="w-3 h-3" />}
              {index === 1 && <MessageCircle className="w-3 h-3" />}
              {index === 2 && <Share2 className="w-3 h-3" />}
              {action}
            </button>
          ))}
        </div>
      </div>
    </div>
  </div>
);

const CampaignDetails = ({ campaignPreview }) => (
  <div className="bg-white rounded shadow-sm border border-gray-200 p-2 mb-2">
    <div className="space-y-1">
      <div className="flex items-center gap-1">
        <div className={`w-2 h-2 rounded-full ${
          campaignPreview.status === 'active' ? 'bg-green-500' : 'bg-yellow-500'
        }`} />
        <span className="text-[10px] font-medium text-gray-600">
          {campaignPreview.status === 'active' ? 'Campaña Activa' : 'Borrador'}
        </span>
      </div>
      <h3 className="text-sm font-medium text-gray-900">{campaignPreview.name}</h3>
      <div className="flex flex-wrap gap-2 text-[10px] text-gray-500">
        {campaignPreview.budget && (
          <span className="flex items-center gap-1">
            <DollarSign className="w-3 h-3" />
            ${campaignPreview.budget}/día
          </span>
        )}
        {campaignPreview.platform && (
          <span className="flex items-center gap-1">
            <Globe className="w-3 h-3" />
            {campaignPreview.platform}
          </span>
        )}
        {campaignPreview.objective && (
          <span className="flex items-center gap-1">
            <Target className="w-3 h-3" />
            {campaignPreview.objective}
          </span>
        )}
      </div>
    </div>
  </div>
);

const PreviewHeader = ({ deviceView, setDeviceView, activeTab, setActiveTab }) => (
  <div className="px-3 py-2 border-b border-gray-200 bg-white space-y-2">
    <div className="flex items-center justify-between">
      <h2 className="text-sm font-semibold text-gray-800">Vista Previa</h2>
      <div className="flex gap-1">
        <button
          onClick={() => setDeviceView('desktop')}
          className={`p-1 rounded flex items-center gap-1 text-[10px] ${
            deviceView === 'desktop' 
              ? 'bg-blue-100 text-blue-700 ring-1 ring-blue-500 ring-opacity-50' 
              : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
          }`}
        >
          <Monitor className="w-3 h-3" />
          Desktop
        </button>
        <button
          onClick={() => setDeviceView('mobile')}
          className={`p-1 rounded flex items-center gap-1 text-[10px] ${
            deviceView === 'mobile' 
              ? 'bg-blue-100 text-blue-700 ring-1 ring-blue-500 ring-opacity-50' 
              : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
          }`}
        >
          <Smartphone className="w-3 h-3" />
          Mobile
        </button>
      </div>
    </div>
    
    <div className="flex gap-1">
      <button
        onClick={() => setActiveTab('google')}
        className={`flex-1 py-1 px-2 text-[10px] font-medium rounded flex items-center justify-center gap-1 
          ${activeTab === 'google'
            ? 'bg-blue-100 text-blue-700 ring-1 ring-blue-500 ring-opacity-50'
            : 'text-gray-600 hover:bg-gray-100'}`}
      >
        <Search className="w-3 h-3" />
        Google Ads
      </button>
      <button
        onClick={() => setActiveTab('meta')}
        className={`flex-1 py-1 px-2 text-[10px] font-medium rounded flex items-center justify-center gap-1 
          ${activeTab === 'meta'
            ? 'bg-blue-100 text-blue-700 ring-1 ring-blue-500 ring-opacity-50'
            : 'text-gray-600 hover:bg-gray-100'}`}
      >
        <Share2 className="w-3 h-3" />
        Meta Ads
      </button>
    </div>
  </div>
);
const ConfirmationSwitch = ({ onConfirm }) => {
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleConfirm = () => {
    setIsConfirmed(!isConfirmed);
    if (!isConfirmed) {
      onConfirm();
    }
  };

  return (
    <div
      className={`relative w-14 h-7 bg-gray-400 rounded-full cursor-pointer transition-colors duration-300 
        ${isConfirmed ? 'bg-blue-500' : ''}`}
      onClick={handleConfirm}
    >
      <div
        className={`absolute w-5 h-5 bg-white rounded-full left-1 top-1 transition-transform duration-300
          ${isConfirmed ? 'translate-x-7' : ''}`}
      />
    </div>
  );
};

const CampaignPreviewSection = ({ campaignPreview, deviceView, activeTab, isCampaignReady, onConfirm }) => {
  if (!campaignPreview?.name) {
    return (
      <div className="flex flex-col items-center justify-center h-full text-center px-4">
        <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center mb-2">
          <Sparkles className="w-5 h-5 text-blue-600" />
        </div>
        <div className="max-w-[180px]">
          <h3 className="text-xs font-medium text-gray-900 mb-1">
            Tu vista previa aparecerá aquí
          </h3>
          <p className="text-[10px] text-gray-500">
            Describe tu campaña en el chat y te ayudaré a crear una vista previa profesional.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={`transition-all duration-300 ${
      deviceView === 'mobile' ? 'max-w-sm' : 'max-w-2xl'
    } mx-auto`}>
      <CampaignDetails campaignPreview={campaignPreview} />
      {activeTab === 'google' ? (
        <GooglePreview campaignPreview={campaignPreview} />
      ) : (
        <MetaPreview campaignPreview={campaignPreview} />
      )}
      {isCampaignReady && (
        <div className="mt-4 flex justify-center items-center gap-4">
          <span className="text-gray-600 font-medium">¿Confirmar campaña?</span>
          <ConfirmationSwitch onConfirm={onConfirm} />
        </div>
      )}
    </div>
  );
};

const AICampaignCreation = ({ onCampaignCreated }) => {
  const { auth } = useContext(AuthContext);
  const [messages, setMessages] = useState([
    { 
      role: 'assistant', 
      content: '👋 ¡Hola! Soy CH3, estoy aquí para crear tus campañas publicitarias y ayudarte en todo lo que necesites.'
    }
  ]);
  const [inputValue, setInputValue] = useState('');
  const [isCreatingCampaign, setIsCreatingCampaign] = useState(false);
  const [campaignPreview, setCampaignPreview] = useState(null);
  const [deviceView, setDeviceView] = useState('desktop');
  const [activeTab, setActiveTab] = useState('google');
  const [showPreview, setShowPreview] = useState(true);
  const [conversationContext, setConversationContext] = useState({});
  const chatRef = useRef(null);
  const [isCampaignReady, setIsCampaignReady] = useState(false);

  const [screenSize, setScreenSize] = useState({
    isMobile: window.innerWidth < 640,
    isTablet: window.innerWidth >= 640 && window.innerWidth < 1024,
    isDesktop: window.innerWidth >= 1024
  });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setScreenSize({
        isMobile: width < 640,
        isTablet: width >= 640 && width < 1024,
        isDesktop: width >= 1024
      });
      if (width >= 1024) {
        setShowPreview(true);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!inputValue.trim()) return;

    const newMessages = [...messages, { role: 'user', content: inputValue }];
    setMessages(newMessages);
    setInputValue('');
    setIsCreatingCampaign(true);

    try {
      const response = await axios.post(
        `${API_URL}/create_campaign_conversation`,
        { 
          message: inputValue,
          context: conversationContext
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.token}`
          }
        }
      );

      const data = response.data;
      
      // Intentar parsear el JSON si está dentro del mensaje
      let parsedData = data;
      if (typeof data.message === 'string' && data.message.includes('{')) {
        try {
          const jsonStartIndex = data.message.indexOf('{');
          const jsonString = data.message.substring(jsonStartIndex);
          const parsed = JSON.parse(jsonString);
          parsedData = {
            ...data,
            ...parsed,
            message: parsed.message
          };
        } catch (e) {
          console.log('No se pudo parsear el JSON del mensaje');
        }
      }
      
      // Actualizar el contexto de la conversación
      if (parsedData.context) {
        setConversationContext(parsedData.context);
      }

      // Agregar la respuesta del asistente al chat
      const newAssistantMessage = { 
        role: 'assistant', 
        content: parsedData.message,
        timestamp: new Date().toISOString()
      };
      setMessages([...newMessages, newAssistantMessage]);
      
      // Actualizar la vista previa si hay detalles de campaña
      if (parsedData.campaign_details) {
        const preview = {
          name: parsedData.campaign_details.nombre,
          platform: parsedData.campaign_details.plataforma,
          budget: parsedData.campaign_details.presupuesto,
          objective: parsedData.campaign_details.objetivo,
          status: 'draft',
          adContent: parsedData.ad_content || {}
        };

        setCampaignPreview(preview);
        setShowPreview(true);
        setIsCampaignReady(true); // La campaña está lista para ser creada

        // Ajustar la pestaña activa según la plataforma
        if (preview.platform?.toLowerCase().includes('google')) {
          setActiveTab('google');
        } else if (preview.platform?.toLowerCase().includes('meta')) {
          setActiveTab('meta');
        }
      }

    } catch (error) {
      console.error('Error:', error);
      setMessages(prev => [
        ...prev,
        {
          role: 'system',
          content: '❌ Hubo un error al procesar tu solicitud. ¿Intentamos de nuevo?',
          type: 'error',
          timestamp: new Date().toISOString()
        }
      ]);
    } finally {
      setIsCreatingCampaign(false);
    }
  };

  // Función para publicar la campaña
  const handlePublishCampaign = async () => {
    setIsCreatingCampaign(true);
    try {
      const response = await axios.post(
        `${API_URL}/create_campaign`,
        { 
          campaign_details: campaignPreview,
          context: conversationContext
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.token}`
          }
        }
      );

      const data = response.data;

      // Agregar mensaje de éxito
      setMessages(prev => [
        ...prev,
        {
          role: 'system',
          content: '✅ ¡Tu campaña ha sido creada exitosamente!',
          timestamp: new Date().toISOString()
        }
      ]);

      // Resetear estados
      setIsCampaignReady(false);
      setCampaignPreview(null);
      setConversationContext({});

      // Llamar a onCampaignCreated
      onCampaignCreated?.(data.new_campaign);

    } catch (error) {
      console.error('Error:', error);
      setMessages(prev => [
        ...prev,
        {
          role: 'system',
          content: '❌ Hubo un error al crear tu campaña. ¿Intentamos de nuevo?',
          type: 'error',
          timestamp: new Date().toISOString()
        }
      ]);
    } finally {
      setIsCreatingCampaign(false);
    }
  };

  return (
    <div className="h-full flex flex-col lg:flex-row bg-gray-50">
      {/* Chat Section */}
      <div className={`${showPreview ? 'lg:w-1/2' : 'w-full'} flex flex-col h-full border-r border-gray-200 
        bg-white`}>
        {/* Header */}
        <div className="px-3 py-2 border-b border-gray-200 bg-gradient-to-r from-blue-500 to-indigo-600">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="w-7 h-7 rounded-full bg-white/10 backdrop-blur-sm flex items-center justify-center">
                <Wand2 className="w-4 h-4 text-white" />
              </div>
              <div>
                <h2 className="text-sm font-semibold text-white leading-tight">CH3 BOT</h2>
                <p className="text-[10px] text-white/80 leading-tight">Creación inteligente de campañas</p>
              </div>
            </div>
            
            {(screenSize.isMobile || screenSize.isTablet) && (
              <button
                onClick={() => setShowPreview(!showPreview)}
                className="p-1 rounded bg-white/10 text-white"
              >
                {showPreview ? <X className="w-4 h-4" /> : <Sparkles className="w-4 h-4" />}
              </button>
            )}
          </div>
        </div>

        {/* Chat Messages */}
        <div 
          ref={chatRef}
          className="flex-1 overflow-y-auto p-2 space-y-2 scrollbar-thin scrollbar-thumb-gray-300 
            scrollbar-track-transparent"
        >
          {messages.map((message, index) => (
            <div
              key={index}
              className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
            >
              <div
                className={`max-w-[85%] px-3 py-2 rounded-xl ${
                  message.role === 'user'
                    ? 'bg-gradient-to-r from-blue-600 to-indigo-600 text-white ml-2'
                    : message.role === 'system'
                    ? message.type === 'error'
                      ? 'bg-red-100 text-red-800 mr-2'
                      : 'bg-green-100 text-green-800 mr-2'
                    : 'bg-gray-100 text-gray-800 mr-2'
                }`}
              >
                <p className="text-xs">{message.content}</p>
                {message.timestamp && (
                  <span className="text-[10px] opacity-50 mt-1 block">
                    {new Date(message.timestamp).toLocaleTimeString()}
                  </span>
                )}
              </div>
            </div>
          ))}
          
          {isCreatingCampaign && (
            <div className="flex justify-center">
              <div className="bg-white shadow-sm rounded-full p-1.5">
                <Loader className="w-4 h-4 animate-spin text-blue-600" />
              </div>
            </div>
          )}
        </div>

        {/* Input Form */}
        <form onSubmit={handleSendMessage} className="p-2 border-t border-gray-200 bg-white">
          <div className="flex items-center gap-2">
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              disabled={isCreatingCampaign}
              placeholder="Describe la campaña que quieres crear..."
              className="flex-1 px-3 py-1.5 border border-gray-300 rounded text-xs
                focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-transparent
                disabled:bg-gray-50 disabled:cursor-not-allowed"
            />
            <button
              type="submit"
              disabled={isCreatingCampaign || !inputValue.trim()}
              className="px-3 py-1.5 bg-gradient-to-r from-blue-600 to-indigo-600 text-white 
                rounded hover:from-blue-700 hover:to-indigo-700 focus:outline-none focus:ring-1 
                focus:ring-blue-500 disabled:opacity-50 transition-all duration-200 flex items-center gap-1.5
                text-xs whitespace-nowrap"
            >
              {isCreatingCampaign ? (
                <>
                  <Loader className="w-3 h-3 animate-spin" />
                  <span className="hidden sm:inline">Procesando...</span>
                </>
              ) : (
                <>
                  <Send className="w-3 h-3" />
                  <span className="hidden sm:inline">Enviar</span>
                </>
              )}
            </button>
          </div>
        </form>
      </div>

      {/* Preview Section */}
      {showPreview && (
        <div className="w-full lg:w-1/2 h-full flex flex-col bg-gray-50">
          <PreviewHeader 
            deviceView={deviceView}
            setDeviceView={setDeviceView}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          <div className="flex-1 overflow-y-auto p-2">
            <CampaignPreviewSection 
              campaignPreview={campaignPreview}
              deviceView={deviceView}
              activeTab={activeTab}
              isCampaignReady={isCampaignReady}
              onConfirm={handlePublishCampaign}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AICampaignCreation;
