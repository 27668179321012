import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import Dashboard from './pages/dashboard/dashboard';
import Analytics from './pages/analytics/Analytics';
import AccountsConnection from './pages/accounts/AccountsConnection';
import FacebookCallback from './components/ui/FacebookCallback';
import GoogleCallback from './components/GoogleCallback';
import Profile from './pages/Profile/Profile';
import GoogleAuthHandler from './components/GoogleAuthHandler';
import ProtectedLayout from './components/ProtectedRoutes';
import { AuthProvider } from './components/context/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/google-callback" element={<GoogleAuthHandler />} />

          {/* Protected routes */}
          <Route element={<ProtectedLayout />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/accounts" element={<AccountsConnection />} />
            <Route path="/facebook-callback" element={<FacebookCallback />} />
            <Route path="/google-callback" element={<GoogleCallback />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/billing" element={<Profile />} />
            <Route path="/security" element={<Profile />} />
            <Route path="/settings" element={<Profile />} />
            <Route path="/help" element={<Profile />} />
          </Route>

          {/* Default redirect */}
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;